<div class="google-places-autocomplete__wrapper"
		 [class.wide-container]="wideFormat">
	<input appGooglePlaces
				 #placesRef="ngx-google-places"
				 class="google-places-autocomplete {{ classes }}"
				 [options]="settings"
				 [deferLoading]="autocompleteOptions?.deferLoading"
				 [class.wide-view]="wideFormat"
				 [placeholder]="placeholder"
				 [value]="autocompleteOptions?.inputString || ''"
				 (input)="onDestinationChange($event)"
				 (click)="onClick()"
         (focus)="onFocus()"
         (blur)="onBlur()"
				 (addressChange)="handleAddressChange($event)" />
	<app-spinner *ngIf="googleAutocompleteLoading" size="24"></app-spinner>
</div>
