export enum DlkAccessTypes {
  granted = 'granted',
  blocked = 'blocked'
}

export enum KnownUserParams {
  email = 'known_email',
  contact = 'known_email_contact',
  guid = 'known_user_guid',
  type = 'known_user_type',
  known = 'known_user',
  childGuid = 'known_child_guid',
  childDLK = 'known_child_dlk'
}

export enum AutoLoginCompleteTypes {
  failed = 'auto_login_failed',
  success = 'auto_login_success',
  imported = 'imported_auto_login',
  retroactive = 'retroactive_auto_login',
  completeRegistration = 'complete_registration_auto_login',
}

export enum KnownLoginStatusTypes {
  inProgress = 'in_progress',
  success = 'success',
  failure = 'failure',
  unknown = 'unknown'
}
