import { Ad } from '@shared/models/ad.model';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import get from 'lodash-es/get';
import { AnalyticsService } from '@shared/services/analytics.service';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { HorizontalScrollAnalyticService } from '@shared/services/horizontal-scroll-analytic.service';
import { Router } from '@angular/router';
import { WindowHelper } from 'g3-common-ui';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeTddClickEvent,
  AdobeTddImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';

@Component({
  selector: 'app-tdd-scroll',
  templateUrl: './tdd-scroll.component.html',
  styleUrls: ['./tdd-scroll.component.less']
})
export class TddScrollComponent implements OnInit, AfterViewInit {

  @Input() public data: ZoneSectionElement;
  @Input() public wideContainerClass = '';
  @Input() public groupTabIndex: number;
  @Input() public wideContainerClassName = '';
  @Input() public hideGhostEffect = false;
  @Input() public isDesktop = true;
  @Input() public isPinnedZone = false;
  @Input() public isEagerLoading = false;

  public todayDeals = [] as TodayDeal[];
  public hovered = false;
  public showSection = true;
  public anchorLink = '';
  public hideArrows = false;

  public processingScroll = false;
  public processingSwipe = false;

  constructor(
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private directClickOutService: DirectClickOutService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private horizontalScrollAnalyticService: HorizontalScrollAnalyticService,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    const target = event.target as Window;
    const adsLength = get(this.data, 'ads.length', 0);
    this.hideArrows = target.innerWidth >= WindowHelper.SIZE_M && target.innerWidth < WindowHelper.SIZE_L && adsLength === 4 || target.innerWidth >= WindowHelper.SIZE_L && adsLength === 5;
  }

  public ngOnInit(): void {
    if (this.data && this.data.min_units && this.data.ads && this.data.ads.length >= this.data.min_units) {
      const adsLength = get(this.data, 'ads.length', 0);
      this.hideArrows = this.isPinnedZone && (window.innerWidth >= WindowHelper.SIZE_M && window.innerWidth < WindowHelper.SIZE_L && adsLength === 4)
        || (window.innerWidth >= WindowHelper.SIZE_L && adsLength === 5);
      this.initTodayDeals(this.data.ads);
      this.showSection = true;
    }

    if (this.data && this.data.name) {
      const sectionName = `s_${this.data.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_todays_deals';
    }
  }

  public ngAfterViewInit(): void {
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
    // * Disabled by G3PROJ-2529
    // this.sendAdobeImpressions();
  }

  public isWideContainer(): boolean {
    return !!this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  public showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.data.is_ebg);
  }

  public async handleClickTodayDeal($event: MouseEvent, todayDeal: TodayDeal): Promise<void> {
    const adobeEvent: AdobeTddClickEvent = {
      event_name: AdobeEventNameEnum.CARD,
      event_type: this.adobeAnalyticsEventsService.getClickOutValueForTdd(todayDeal),
      properties: await this.adobeAnalyticsEventsService.getOfferAdobeDataFromTodayDeal(todayDeal , AdobeEventNameEnum.CARD, 'offer', {zone: this.data.name})
    };
    void this.directClickOutService.todayDeals.handleClick(todayDeal, undefined, adobeEvent);
  }


  public async onScrollEvent(direction: string): Promise<void> {
    if (this.processingScroll) {
      return;
    } else {
      this.processingScroll = true;
      this.analyticsService.eventsTrack(
        this.horizontalScrollAnalyticService.getAnalyticsEvents('scroll', 'tdd', { direction })
      );
      await new Promise(res => setTimeout(res, 5000)); // no more than 1 scroll per 5 seconds per scroll
      this.processingScroll = false;
    }
  }

  public async onSwipeEvent(direction: string): Promise<void> {
    if (this.processingSwipe) {
      return;
    } else {
      this.processingSwipe = true;
      this.analyticsService.eventsTrack(
        this.horizontalScrollAnalyticService.getAnalyticsEvents('swipe', 'tdd', { direction })
      );
      await new Promise(res => setTimeout(res, 5000)); // no more than 1 scroll per 5 seconds per scroll
      this.processingSwipe = false;
    }
  }

  public trackById(index: number, item: TodayDeal): number {
    return item.id;
  }

  private initTodayDeals(ads: Ad[]): void {
    this.todayDeals = ads.map((i: Ad) => (new TodayDeal({
      guid: i.guid,
      image_url: i.dbImageUrl,
      headline: i.titleShort,
      company_name: i.company,
      company_brand_tag_logo: i.vendorImage,
      coupon_code: i.code,
      url: i.destinationUrl,
      offer_group: i.offerGroup,
      curr_price: i.currentPrice,
      orig_price: i.originalPrice,
      pinned: i.isPinned,
      tag_text: i.tagText,
      is_ebg: i.isEbg,
      new_tab: i.isNewTab,
      shopping_category: i.shopping_category,
      urlType: i.destination_url_type
    })));
  }

  private sendAdobeImpressions(): void {
    // TODO revisit to reimplement after changes in G3PROJ-2877 and restrictions from G3PROJ-2529 lifted
    // const adobeEvent: AdobeTddImpresssionsEvent = {
    //   event_name: AdobeEventNameEnum.CARD,
    //   event_type: AdobeEventTypeEnum.IMPRESSION,
    //   properties: {
    //     // @ts-ignore
    //     impressions: this.todayDeals.map(
    //       (todayDeal, index) => {
    //         const adobeData = this.adobeAnalyticsEventsService.getOfferAdobeDataFromTodayDeal(todayDeal, AdobeEventNameEnum.CARD, `offer${index + 1}`, { zone: this.data.name });
    //         if (adobeData[`offer${index + 1}`].isEbg) {
    //           delete adobeData[`offer${index + 1}`].shopping_category;
    //         }
    //         delete adobeData[`offer${index + 1}`].isEbg;
    //         return adobeData;
    //       }
    //     )
    //   }
    // };
    //
    // void this.adobeAnalyticsEventsService.storePageEvent(adobeEvent, AdobeEventLocation.Homepage);
  }
}
