<ng-container>
  <app-decisions-fly-in [groupTabIndex]="40000"
                        *ngIf="isShowAdFlyIn()"
                        [data]="adFlyIn"
                        (cardClicked)="onClickedAdFlyIn()"
                        (ctaClicked)="onClickedAdFlyIn()"
                        (showed)="onShowedAdFlyIn($event)"
                        (closed)="onCloseAdFlyIn()">
  </app-decisions-fly-in>
  <app-open-enrollment *ngIf="enrollmentData"
                       [openEnrollment]="enrollmentData"
                       [isNonPaylogixCountry]="isNonPaylogixCountry"
                       [groupTabIndex]="40010"
                       [wideContainerClass]="wideContainerClass"
                       [wideContainerClassName]="wideContainerClassName">
  </app-open-enrollment>

  <app-pinned-zone *ngIf="pinnedZoneElement" [zoneElement]="pinnedZoneElement"></app-pinned-zone>

  <ng-container *ngIf="firstSection">
    <app-zone-section [zoneSection]="firstSection"
                      [pinnedSection]="pinnedZone && pinnedZone.section === '1' ? pinnedZone : null"
                      [wideContainerClass]="wideContainerClass"
                      [elementsToEagerLoading]="eagerLoadingPerSection[firstSection.section]"
                      [initialTabIndex]="40020"
                      [wideContainerClassName]="wideContainerClassName"
                      [isHomepageTakeoverEnabled]="!!enrollmentData"
                      [userCountry]="userCountry"
                      (displayed)="handleDisplayedSection($event)">
    </app-zone-section>
  </ng-container>

  <div appInfinite
       thresholdFromBottom="1500"
       (loadMore)="loadMore($event)">
    <ng-container *ngIf="secondSection">
      <app-zone-section [zoneSection]="secondSection"
                        [pinnedSection]="pinnedZone && pinnedZone.section === '2' ? pinnedZone : null"
                        [wideContainerClass]="wideContainerClass"
                        [elementsToEagerLoading]="eagerLoadingPerSection[secondSection.section]"
                        [initialTabIndex]="80000"
                        [wideContainerClassName]="wideContainerClassName"
                        [userCountry]="userCountry"
                        (displayed)="handleDisplayedSection($event)">
      </app-zone-section>
    </ng-container>
    <ng-container *ngIf="isAdsViewInited">
      <app-brands-list *ngIf="isEnabledFeaturedBrands"
                       [groupTabIndex]="122700"
                       [wideContainerClass]="wideContainerClass"
                       [wideContainerClassName]="wideContainerClassName"></app-brands-list>
    </ng-container>
    <ng-container *ngFor="let zoneSection of otherSections; trackBy: trackBySection; let i = index">
      <app-zone-section [zoneSection]="zoneSection"
                        [pinnedSection]="pinnedZone && restZones.includes(pinnedZone.section) ? pinnedZone : null"
                        [elementsToEagerLoading]="eagerLoadingPerSection[zoneSection.section]"
                        [wideContainerClass]="wideContainerClass"
                        [initialTabIndex]="123000 + (40000 * i)"
                        [wideContainerClassName]="wideContainerClassName"
                        [userCountry]="userCountry"
                        [isSectionVisible]="displayedZones.has(zoneSection.section)"
                        (displayed)="handleDisplayedSection($event)">
      </app-zone-section>
    </ng-container>
  </div>

  <app-spinner *ngIf="isLoading"
               [isHomePage]="true">
  </app-spinner>

</ng-container>
