/* eslint-disable radix */
import { Ad, AdSource } from '@shared/models/ad.model';
import { AdsOffersService } from '@shared/services/ads/ads-offers.service';
import { cdnHostTypes, ConfigService } from '@shared/services/config.service';
import {
  filter,
  map,
  switchMap,
  take
} from 'rxjs/operators';
import { Observable, ReplaySubject, forkJoin, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProfileService } from '@shared/services/profile/profile.service';
import { SearchAdsParams, SearchAdsService } from '@shared/services/ads/search-ads.service';
import { SearchAdsResponse } from '@shared/services/ads/search-ads-items-response.interface';
import { SearchResponse, SearchService } from '@shared/services/search/search.service';
import { TodayDealsService } from '@shared/services/today-deals.services';
import { ZoneSection, ZoneSectionElement, ZoneSectionElementConfigItem } from '@zones/interfaces/zones-search-response.interface';
import { PlatformService, SystemMessageService } from 'g3-common-ui';
import { ZoneElementTypes } from './zones.service';
import { SearchOfferItem } from '@app/shared/interfaces/search-offer-item.interface';
import { TDD_CLASS_ID, TDD_CLASS_TITLE } from '@app/shared/constants/offer.constants';

const contentRequiredTypes = ['cards_fixed', 'cards_scroll'];

@Injectable({
  providedIn: 'root'
})
export class ZonesSectionsService {

  private zoneElement$ = new ReplaySubject<ZoneSectionElement>();

  constructor(
    private configService: ConfigService,
    private searchAdsService: SearchAdsService,
    private adsOffersService: AdsOffersService,
    private todayDealsService: TodayDealsService,
    private searchService: SearchService,
    private profileService: ProfileService,
    private platformService: PlatformService,
    private systemMessageService: SystemMessageService
  ) {
  }

  public async getZoneSectionData(zoneSection: ZoneSection): Promise<ZoneSection> {
    if (!zoneSection.elements || zoneSection.elements.length === 0) {
      return zoneSection;
    }

    const resultsMap = new Map<number, ZoneSectionElement>();
    let indexToEmit = 0;

    const elementPromises: Promise<void>[] = [];
    for (let i = 0; i < zoneSection.elements.length; i++) {
      const element = zoneSection.elements[i];

      const promise = this.getZoneSectionElementData(element)
        .then(ads => {
          element.ads = ads;
          resultsMap.set(i, element);

          // This ensure that even if first completed promise is i === 3, order 0...5 would be maintained
          while (resultsMap.has(indexToEmit)) {
            this.zoneElement$.next(resultsMap.get(indexToEmit));
            resultsMap.delete(indexToEmit);
            indexToEmit++;
          }

        })
        .catch(err => {
          console.error(this.systemMessageService.getErrorFromException(err));
          element.ads = [];
          resultsMap.set(i, element);

          while (resultsMap.has(indexToEmit)) {
            this.zoneElement$.next(resultsMap.get(indexToEmit));
            resultsMap.delete(indexToEmit);
            indexToEmit++;
          }

        });

      elementPromises.push(promise);
    }

    await Promise.all(elementPromises);

    return zoneSection;
  }

  public getSectionElement(guid: string): Observable<ZoneSectionElement> {
    return this.zoneElement$.pipe(
      filter(el => el.guid === guid),
      take(1),
    );
  }

  private async getZoneSectionElementData(zoneSectionElement: ZoneSectionElement): Promise<Ad[]> {
    const [adsResponse, offersResponse] = await forkJoin([
      // /1. fetch data from ads service
      this.getAdsForZoneSectionElement(zoneSectionElement),
      // 2. fetch data from natural search
      this.getNaturalResultsForZoneSectionElement(zoneSectionElement)
    ]).toPromise();

    return this.getZoneSectionElementDataByAdsAndOffers(zoneSectionElement, adsResponse, offersResponse);
  }

  private async getAdsForZoneSectionElement(zoneSectionElement: ZoneSectionElement): Promise<SearchAdsResponse> {
    if (!zoneSectionElement.ad_type) {
      return {
        items: []
      };
    }

    const adsResponse = await firstValueFrom(this.searchAdsService.getAds(
      this.getSearchAdsParamsForZoneSectionElement(zoneSectionElement),
      zoneSectionElement.max_units,
      this.getDismissedDecisions()
    ));

    // extend ads with offers info
    return this.adsOffersService.getAds(adsResponse);
  }

  private getDismissedDecisions(): string {
    let excludeGuids = [];
    this.profileService.profileData$
      .pipe(
        take(1),
        filter(i => !!i.dismissedDecisions),
        map(i => i.dismissedDecisions))
      .subscribe(item => excludeGuids = item.map(ad => ad.guid));
    return excludeGuids.toString();
  }

  private async getNaturalResultsForZoneSectionElement(zoneSectionElement: ZoneSectionElement): Promise<SearchResponse> {
    // 2. fetch data from search

    const domain = this.configService.getCdn(cdnHostTypes.apiHost);
    const naturalQueryString = zoneSectionElement.natural?.split('?')[1] || '';
    const queryString = this.getQueryString(naturalQueryString, zoneSectionElement);
    const subDomain = this.configService.getSubdomain();
    const naturalSearchResult = await firstValueFrom(this.profileService.profileData$
      .pipe(
        take(1),
        map(profileData => profileData.countryLast || 'US'),
        switchMap(async userCountryCode => this.searchService.makeSearchNaturalCall(queryString, domain, subDomain, userCountryCode))
      ));

    return naturalSearchResult;


  }

  private getQueryString(queryParams: string, zoneSectionElement: ZoneSectionElement): string {
    const params = new URLSearchParams(queryParams);

    if (params.has('tdg')) {
      const tdgValue = params.get('tdg');
      params.delete('tdg');
      params.set('ofg', tdgValue);
      params.set('f_class', TDD_CLASS_ID);
    }

    if (zoneSectionElement.type === ZoneElementTypes.TddScroll) {
      params.set('f_class', TDD_CLASS_ID);
      params.set('is_tdd_search', 'true');
    }

    if (params.get('f_class') === TDD_CLASS_ID) {
      params.set('orderBy', 'tdd_pinned');
    }

    if (!params.has('orderBy')) {
      params.set('orderBy', 'relevance');
    }

    if (zoneSectionElement.type === ZoneElementTypes.CardsProduct) {
      params.set('f_class', '7');
    }
    if (zoneSectionElement.type === ZoneElementTypes.TopBrandsNames) {
      params.set('orderByCat', 'popularity');
      params.set('skip_editorial', 'true');
      params.set('status', 'active');
    }


    if (contentRequiredTypes.includes(zoneSectionElement.type)) {
      params.set('isContentRequired', 'true');
    }

    return `?${params.toString()}&limit=${zoneSectionElement.max_units}&dontSaveUserSearch=1`;
  }

  getSearchAdsParamsForZoneSectionElement(zoneSectionElement: ZoneSectionElement): SearchAdsParams {
    const filteredTagTypes = [
      'card^homepage',
      'cta^homepage',
      'cta^product',
      'widget^homepage',
      'noti^strip',
      'noti^block'
    ];
    const isAdTypeFiltered = filteredTagTypes.includes(zoneSectionElement.ad_type);

    return {
      type: zoneSectionElement.ad_type,
      keywordTypes: zoneSectionElement.ad_type_source,
      keywords: zoneSectionElement.ad_type_reference,
      sites: this.configService.getOption('subdomain'),
      excludeBrands: this.configService.getExcludeBrands().join(','),
      excludeCategories: this.configService.getExcludeCategories().join(','),
      ...(isAdTypeFiltered && { siteTag: this.platformService.currentPlatform.SITE_TAG })
    };
  }

  async generateZoneSectionElementData(zoneSectionElement: ZoneSectionElement, ads: SearchAdsResponse, offers: SearchResponse): Promise<Ad[]> {

    switch (zoneSectionElement.type) {
      case 'tdd_scroll':
        return this.getZoneSectionElementDataForTdd(zoneSectionElement);
      default:
        return this.getZoneSectionElementDataByAdsAndOffers(zoneSectionElement, ads, offers);
    }
  }

  async getZoneSectionElementDataForTdd(zoneSectionElement: ZoneSectionElement): Promise<Ad[]> {
    const queryParamsString = zoneSectionElement.natural.split('?')[1];
    const naturalUrlSearchParams = new URLSearchParams(queryParamsString);
    const result = await this.todayDealsService.getTodayDeals(zoneSectionElement.max_units, naturalUrlSearchParams).toPromise();
    return result && result.length > 0 ? result.map(i => this.adsOffersService.getAdFromTddItem(i)) : [];
  }

  async getZoneSectionElementDataByAdsAndOffers(zoneSectionElement: ZoneSectionElement, ads: SearchAdsResponse, offers: SearchResponse): Promise<Ad[]> {
    // apply value from z_hybrid_config
    const config = this.getZoneSectionElementHybridConfig(zoneSectionElement);
    const adsPriorityMultiplier = parseInt(this.getZoneSectionElementHybridConfigValue(config, AdSource.BSS, '1'));
    const naturalSearchPriorityMultiplier = parseInt(this.getZoneSectionElementHybridConfigValue(config, AdSource.Natural, '1'));

    const result = [...ads.items.map(i => this.adsOffersService.getAdFromSearchAdsItem(i, adsPriorityMultiplier))];

    // get inherited offers guids
    const offerGUIDsFromAds = this.adsOffersService.getOfferGUIDsFromAds(ads);

    return result
      .concat(...(offers.items || [])
        // remove duplicates inside of offers
        .filter(i => i.guid && !offerGUIDsFromAds.includes(i.guid))
        .map((i, index) => {
          // compute Sort_Score for Natural Array using value as defined as: [100 - array_position],
          // where array_position is it's index in the array
          i.priority = (100 - index);
          return this.getAdFromOfferItemByClass(i, naturalSearchPriorityMultiplier);
        })
      )
      // sort by priority
      .sort((a: Ad, b: Ad) => this.sortZoneSectionElementDataItems(a, b, zoneSectionElement))
      // return first N items
      .slice(0, zoneSectionElement.max_units);
  }

  getAdFromOfferItemByClass(i: SearchOfferItem, naturalSearchPriorityMultiplier: number): Ad {
    return i.class !== TDD_CLASS_TITLE
      ? this.adsOffersService.getAdFromSearchOfferItem(i, naturalSearchPriorityMultiplier)
      : this.adsOffersService.getAdFromOfferTddItem(i, naturalSearchPriorityMultiplier);
  }

  getZoneSectionElementHybridConfig(zoneSectionElement: ZoneSectionElement): ZoneSectionElementConfigItem[] {
    return zoneSectionElement.hybrid_config.split('|').map(i => {
      const chunks = i.split(':');
      return (chunks.length === 2) ? { key: chunks[0], value: chunks[1] } : null;
    }).filter(i => !!i);
  }

  getZoneSectionElementHybridConfigValue(data: ZoneSectionElementConfigItem[], key: string, defaultValue: string): string {
    const item = data.find(i => i.key.toLocaleLowerCase() === key.toLocaleLowerCase());
    return item ? item.value : defaultValue;
  }

  sortZoneSectionElementDataItems(a: Ad, b: Ad, zoneSectionElement: ZoneSectionElement): number {

    if (zoneSectionElement?.ad_type === 'cta^homepage') {
      // skip sorting for cta^homepage ads, since they already sorted by specific rule on the server
      return 0;
    }

    if (a.priority > b.priority) {
      return -1;
    }
    if (a.priority < b.priority) {
      return 1;
    }
    if (a.source === AdSource.BSS) {
      return -1;
    }
    return 0;
  }

}
