import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ShareInfoService } from '@shared/services/share-info.service';
import { TodayDeal } from '@shared/models/today-deal.model';
import {
  ImageUrlPipeOptions,
  WINDOW,
  WindowHelper,
} from 'g3-common-ui';
import { ImageHandlerService } from '@shared/services/image-handler/image-handler.service';

const backgroundOptionsXs = { w: 220, h: 112, alternate: false };
const backgroundOptionsS = { w: 285, h: 200, alternate: false };
const backgroundOptionsM = { w: 217, h: 190, alternate: false };
const backgroundOptionsOther = { w: 265, h: 200, alternate: false };

@Component({
  selector: 'app-today-deal-item',
  templateUrl: './today-deal-item.component.html',
  styleUrls: ['./today-deal-item.component.less']
})
export class TodayDealItemComponent implements OnInit {

  @Input() public todayDeal: TodayDeal;
  @Input() public usedFromSearch = false;
  @Input() public groupTabIndex: number;
  @Input() public wideContainerClass = '';
  @Input() public isEagerLoading = false;

  public imageLoading = 'lazy';

  constructor(@Inject(WINDOW) private window: WINDOW,
    private shareInfoService: ShareInfoService,
    private analyticsService: AnalyticsService,
    public imageHandlerService: ImageHandlerService,) { }

  public ngOnInit(): void {
    this.imageLoading = this.isEagerLoading ? 'eager' : 'lazy';
  }

  public getTrimCount(): number {
    const windowWidth = window.innerWidth;
    // layout XL + L
    if (windowWidth >= 1280) {
      return this.usedFromSearch ? 38 : 50;
      // layout M
    } else if (windowWidth >= 1024) {
      return 38;
      // layout S, M, L, XL
    } else if (windowWidth >= 768) {
      return this.usedFromSearch ? 38 : 50;
      // layout XS
    } else {
      return 32;
    }
  }

  public shareClick(): void {
    const originUrl = `${this.window.location.origin}`;
    const link = `${originUrl}/home/?tdd_guid=${this.todayDeal.guid}`;
    const { headline } = this.todayDeal;
    const tddInfo = `${this.todayDeal.company_name}\n${headline}\n${link}`;
    this.shareInfoService.copyInfo(tddInfo);

    // Track Analytics
    const action = 'share-link-click';
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel(action, {
        url: link
      }),
      new AnalyticsGAEventModel(action, {
        category: 'share-link',
        label: `url:${link}`
      })
    ]);
  }

  public hasCouponCode(todayDeal: TodayDeal): boolean {
    return !!(todayDeal && todayDeal.coupon_code);
  }

  public getCtaButtonTitle(todayDeal: TodayDeal): string {
    return (todayDeal && todayDeal.coupon_code) ? 'GET CODE' : 'BUY NOW';
  }

  public getBackgroundOptions(): ImageUrlPipeOptions {
    const width = this.window.innerWidth;

    if (width <= WindowHelper.SIZE_S) {
      return backgroundOptionsXs;
    } else if (width <= WindowHelper.SIZE_M) {
      return backgroundOptionsS;
    } else if (width <= WindowHelper.SIZE_L) {
      return backgroundOptionsM;
    } else {
      return backgroundOptionsOther;
    }
  }

  public makeBackgroundImageUrl(url: string): string {
    return `url(${url})`;
  }
}
