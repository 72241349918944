import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { TabIndexService } from '@shared/services/tabIndex.service';
import { ZoneElementTypes } from '@app/zones/services/zones.service';
import { ZoneSection, ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { ZonesSectionElementsAnalyticsService } from '@zones/services/analytics/zones-section-elements-analytics.service';
import { ZonesSectionsService } from '@zones/services/zones-sections.service';
import { Subject, concatMap, from, takeUntil, Observable } from 'rxjs';

@Component({
  selector: 'app-zone-section',
  templateUrl: './zone-section.component.html',
  styleUrls: ['./zone-section.component.less']
})
export class ZoneSectionComponent implements OnInit, OnDestroy {

  @Input() zoneSection: ZoneSection;
  @Input() initialTabIndex: number;
  @Input() pinnedSection: { section: string; data: ZoneSectionElement } = null;
  @Input() isHomepageTakeoverEnabled = false;
  @Input() userCountry: string;
  @Input() elementsToEagerLoading = 0;
  @Input() isSectionVisible = true;

  // temporary input fields
  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Output() displayed = new EventEmitter<ZoneSection>();

  zoneElementTypes = ZoneElementTypes;
  elements: ZoneSectionElement[] = [];
  private destroy$: Subject<void> = new Subject();

  constructor(
    private zonesSectionsAnalyticsService: ZonesSectionElementsAnalyticsService,
    private zonesSectionsService: ZonesSectionsService,
    private analyticsService: AnalyticsService,
    private tabIndexService: TabIndexService,
  ) {
  }

  isCardsSection(zoneElement: ZoneSectionElement, type: ZoneElementTypes): boolean {
    return !(this.pinnedSection && this.pinnedSection.section === this.zoneSection.section
      && this.pinnedSection.data.type === type && this.pinnedSection.data.guid === zoneElement.guid)
      && (zoneElement.type === type);
  }

  ngOnInit(): void {
    if (!this.zoneSection) {
      return;
    }

    from(this.zoneSection.elements).pipe(
      takeUntil(this.destroy$),
      concatMap(el => this.zonesSectionsService.getSectionElement(el.guid))
    ).subscribe({
      next: data => {
        this.elements.push(data);
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
        this.displayed.emit(this.zoneSection);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInitElement(zoneSectionElement: ZoneSectionElement): void {
    this.analyticsService.eventsTrack(
      this.zonesSectionsAnalyticsService.getAnalyticsEvents('items-selected', zoneSectionElement)
    );
  }

  onDisplayedElement(zoneSectionElement: ZoneSectionElement): void {
    this.analyticsService.eventsTrack(
      this.zonesSectionsAnalyticsService.getAnalyticsEvents('view-ads', zoneSectionElement)
    );
  }

  getTabIndexForZone(zoneElementOrder: number): number {
    return this.tabIndexService.getTabIndexForZone(this.initialTabIndex, zoneElementOrder);
  }

  public getMarginClasses(): string {
    if (this.zoneSection?.page === 'homepage' && this.zoneSection?.section === '1') {
      const firstElement = this.zoneSection?.elements[0];
      let className;
      switch (firstElement?.type) {
        case ZoneElementTypes.CardsFixed:
          className = 'cards-fixed-first';
          break;
        case ZoneElementTypes.CardsScroll:
          className = 'cards-scroll-first';
          if (!firstElement?.name) {
            className += ' no-title no-url';
          } else if (!firstElement?.view_all_url) {
            className += ' no-url';
          }
          break;
        case ZoneElementTypes.TddScroll:
          className = 'today-deals-first';
          break;
        case ZoneElementTypes.WidgetsMini:
          className = 'widgets-mini-first';
          break;
        case ZoneElementTypes.WidgetsLarge:
          className = 'widgets-large-first';
          break;
        case ZoneElementTypes.CtaBlock:
          className = 'cta-block-first';
          break;
        case ZoneElementTypes.NotiBlock:
          className = 'noti-block-first';
          break;
      }
      if (!this.isHomepageTakeoverEnabled) {
        className += ' no-takeover';
      }
      if (!firstElement?.name && firstElement?.type !== ZoneElementTypes.CardsScroll) {
        className += ' no-title';
      }
      return className;
    }
  }

  public trackByZoneGuid(index: number, item: ZoneSectionElement): string {
    return item.guid;
  }

  public isEagerLoading(index: number): boolean {
    return this.elementsToEagerLoading !== 0 && index <= (this.elementsToEagerLoading - 1);
  }
}
