import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  Input,
  Injectable,
  Inject
} from '@angular/core';
import { WINDOW } from 'g3-common-ui';

@Injectable({ providedIn: 'root' })
@Directive({
  selector: '[appGooglePlaces]',
  exportAs: 'ngx-google-places'
})
export class NgxGooglePlacesDirective implements OnInit {
  @Input() public options: google.maps.places.AutocompleteOptions;

  @Output() public addressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();

  public element: HTMLInputElement;
  public autocomplete: google.maps.places.Autocomplete;
  public eventListener: google.maps.MapsEventListener;
  public place: google.maps.places.PlaceResult;

  private scriptInjected: boolean;

  public constructor(
    elRef: ElementRef,
    private ngZone: NgZone,
    @Inject(WINDOW) private window: WINDOW,
  ) {
    this.element = elRef.nativeElement;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadGoogleMaps();

    this.autocomplete = new google.maps.places.Autocomplete(this.element);

    // ? Monitor place changes in the input
    if (this.autocomplete.addListener !== null) {
      this.eventListener = google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.handleChangeEvent());
    }
  }

  async loadGoogleMaps(): Promise<void> {
    if (this.scriptInjected) {
      return;
    }

    try {
      await this.injectGoogleMapsScript();
      this.scriptInjected = true;
    } catch (error) {
      console.error(error);
    }


  }

  public reset(): void {
    this.autocomplete?.setComponentRestrictions(this.options?.componentRestrictions || null);
    this.autocomplete?.setTypes(this.options?.types || []);
  }

  public handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete?.getPlace();

      if (this.place) {
        this.addressChange.emit(this.place);
      }
    });
  }

  private async injectGoogleMapsScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const src = 'https://maps.googleapis.com/maps/api/js?client=gme-entertainmentbenefits&libraries=places';
      const instance = document.querySelector('#googleapis-maps');

      if (instance) {
        instance.addEventListener('load', (): void => resolve());
        if (window?.google) {
          resolve();
        }
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.id = 'googleapis-maps';
        script.onload = (): void => resolve();
        script.onerror = (): void => reject();
        document.body.appendChild(script);
      }
    });
  }

}
