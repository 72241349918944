<section class="cards-fixed container main-container wide-container category-item-container"
         [attr.id]="anchorLink"
         [class.wide-endpoint]="isWideContainer()"
         *ngIf="!!data && data.ads && isEnoughAds">
  <app-zone-header *ngIf="!!data.name"
                   [data]="data"
                   [groupTabIndex]="groupTabIndex + 1"
                   (showAllClick)="showAllClickHandler($event)"></app-zone-header>
  <div class="cards-fixed_body">
    <app-open-enrollment-ad class="open-enrollment-ad col-12 col-md-3 sel-ad-item tier-1"
                            [sectionName]="getEnrollmentInfo().section_name"
                            [startDate]="getEnrollmentInfo().start_date"
                            [endDate]="getEnrollmentInfo().end_date"
                            [moreInfo]="getEnrollmentInfo().more_info"
                            [class.wide-endpoint]="isWideContainer()"
                            [groupTabIndex]="1000"
                            role="presentation"
                            *ngIf="isEnrollment">
    </app-open-enrollment-ad>
    <ng-container *ngFor="let ad of ads; let i = index; trackBy: addItemTrackBy">
      <app-ad-item *ngIf="!isProductCard(ad)"
                   class="sel-ad-item"
                   parentType="cards_fixed"
                   [zoneName]="this.data.name"
                   [isEagerLoading]="isEagerLoading"
                   [groupTabIndex]="(i + 1) % 2 === 0 && i === 0 ? groupTabIndex + 10 : groupTabIndex"
                   [class.tier-1]="isDoubleWideCard(i) && !isEnrollment"
                   [isTier1]="isDoubleWideCard(i) && !isEnrollment"
                   [class.wide-endpoint]="isWideContainer()"
                   [class.only-xl]="isOnlyXL(i)"
                   [class.hide-last-for-oe]="hideLastForOE(i)"
                   [ad]="ad"
                   (displayed)="onDisplayedAd($event)"
                   (clicked)="onClickedAd(ad, i)"
                   (clickedCta)="onClickedAdCta($event, i)"></app-ad-item>
    </ng-container>
  </div>
</section>
