<div [attr.id]="anchorLink"
     class="container main-container {{wideContainerClass}}"
     *ngIf="ctaProduct">
  <div class="cta-decision"
       (click)="handleCtaClick()"
       [class.wide-endpoint]="!!wideContainerClass">

    <div class="cta-decision-text-container">
      <img *ngIf="ctaProduct.logo_url"
           [ngSrc]="ctaProduct.logo_url | imageUrl : { w: 80, h: 27 }"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           [loading]="imageLoading"
           width="80"
           height="27"
           class="cta-decision-logo"
           alt="Product logo"
           (error)="imageHandlerService.swapImage($event.target)"/>
      <p class="cta-decision-header">
        {{ctaProduct.header}}
      </p>

      <p class="cta-decision-text">
        {{ctaProduct.body}}
      </p>

      <button class="btn btn-primary"
              *ngIf="ctaProduct?.button_txt"
              [appAutoTabIndex]="groupTabIndex">
        {{ctaProduct.button_txt}}&nbsp;
        <svg *ngIf="!ctaProduct.isEbg"
             xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="16"
             viewBox="0 0 16 16">
          <path fill="#FFF"
                fill-rule="nonzero"
                d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
        </svg>
      </button>
    </div>

    <div *ngIf='ctaProduct.image_url'
         class="cta-decision-image-block">
      <div class="cta-decision-image">
        <img
          [ngSrc]="foregroundUrl"
          (error)="imageHandlerService.swapImage($event.target)"
          [placeholder]="imageHandlerService.getDefaultPlaceholder"
          [loading]="imageLoading"
          class="foreground-cta-image"
          alt=""
          fill>
      </div>
      <div class="cta-decision-gradiend-bg"></div>
      <div class="cta-decision-right-bg"></div>
      <div class="cta-decision-left-circle"></div>
      <div class="cta-decision-right-circle"></div>
    </div>

  </div>
</div>
