import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { ConfigService } from '@shared/services/config.service';
import { CustomWidgetComponent } from '../../custom-widget/custom-widget.component';
import {
  ImageUrlPipeOptions,
  UrlHelperService,
} from 'g3-common-ui';
import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { OfferBadgeSettingsModel } from '@shared/models/offer-badge-settings.model';
import { Ad } from '@app/shared/models/ad.model';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeEventNameEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { ImageHandlerService } from '@shared/services/image-handler/image-handler.service';

const backgroundOptions = { w: 340, h: 438, alternate: false };

@Component({
  selector: 'app-hotel-widget',
  templateUrl: './hotel-widget.component.html',
  styleUrls: ['./hotel-widget.component.less'],
  providers: [OfferBadgeService]
})

export class HotelWidgetComponent implements CustomWidgetComponent, OnInit {

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  model: CustomWidgetModel;
  groupTabIndex: number;
  header: string;
  logoUrl: string;
  backgroundImage: string;
  isEagerLoading: boolean;

  constructor(
    public imageHandlerService: ImageHandlerService,
    public configService: ConfigService,
    private urlHelper: UrlHelperService,
    private analyticsService: AnalyticsService,
    public adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private offerBadgeService: OfferBadgeService,
  ) { }

  ngOnInit(): void {
    this.initWidgetDynamicData();
    this.initDefaultValues();

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-load', {
        name: 'hotel'
      }),
      new AnalyticsGAEventModel('widget-load', { category: 'hotel' })
    ]);

    this.offerBadgeService.setBadgeSettings(new OfferBadgeSettingsModel({
      lightPillsCount: 1,
      loyaltyPillsOnly: true
    }));
  }

  initDefaultValues(): void {
    if (this.model) {
      this.model.vendorId = 123;
    }
  }

  initWidgetDynamicData(): void {
    this.header = this.model && this.model.header ? this.model.header : 'Save up to 60% on Hotels';
    this.logoUrl = this.model && this.model.logoUrl ? this.model.logoUrl : '/assets/taw-hoteldiscount-logo-square.png';
    this.backgroundImage = this.model && this.model.imageUrl ? this.model.imageUrl : this.getWidgetConfigImage();
  }

  getWidgetConfigImage(): string {
    const widgetConfig = this.configService.getWidgetConfig('hotel_discounts');
    return widgetConfig ? widgetConfig.img_url : '';
  }

  getBackgroundOptions(): ImageUrlPipeOptions {
    return backgroundOptions;
  }

  hotelClickOut(data: ClickOutModel): void {
    const clickOut = this.model.isEbg || !this.urlHelper.isExternalUrl(this.model.destinationUrl) ? 'internal' : 'external';

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-click', {
        name: 'hotel',
        action: 'search',
        click_out: clickOut
      }),
      new AnalyticsGAEventModel('widget-click', { category: 'hotel', label: `search | ${clickOut}` })
    ]);

    void this.sendAdobeAnalytics();
  }

  private async sendAdobeAnalytics(): Promise<void> {
    const ad = new Ad({
      guid: this.model.guid,
      isEbg: this.model.isEbg,
      content: [{ key: 'header', value: this.model.header }],
      destinationUrl: this.model.destinationUrl,
      brand_name: this.model.brandName,
      catCodeTagIds: this.model.catCodeTagIds,
    });
    this.adobeAnalyticsEventsService.sendWidgetClick({
      event_name: AdobeEventNameEnum.WIDGET,
      event_type: this.adobeAnalyticsEventsService.getClickOutValueForWidget(ad),
      properties: await this.adobeAnalyticsEventsService.getWidgetAdobeData(ad, 'offer', { size: 'large' })
    });
  }

}
