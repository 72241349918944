/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute, Router } from '@angular/router';
import { AUSTIN_COORDINATES, AutocompleteSettings, GooglePlacesAutocompleteOptions, SEARCH_RADIUS } from '@app/widgets/interfaces/autocomplete-settings.interface';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { ModalAlertComponent } from '@app/shared/components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import { TICKETS_MINI } from '@app/widgets/constants/widget-ids.constant';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';
import { TICKETS_CATEGORY } from '@app/shared/constants/cat-code-tags.constants';

@Component({
  selector: 'app-tickets-form',
  templateUrl: './tickets-form.component.html',
  styleUrls: ['./tickets-form.component.less', '../../search/common-widget.style.less']
})

export class TicketsFormComponent implements OnInit {

  @Input() widgetClass = '';
  @Input() model: CustomWidgetModel;
  @Output() buttonClick: EventEmitter<{ url: string }> = new EventEmitter<{ url: string }>();

  destination = '';
  ticketsEntity = '';
  destinationUrl = '';
  autocompleteOptions: GooglePlacesAutocompleteOptions = { inputString: '', deferLoading: true };
  useDefaultDestination = false;
  autocompleteSettings: AutocompleteSettings = {
    inputPlaceholderText: 'City or ZIP',
    showSearchButton: false,
    currentLocIconUrl: '/assets/icons/current-location.png',
    locationIconUrl: '/assets/icons/location.png',
    showRecentSearch: false,
    inputString: '',
    geoLocation: AUSTIN_COORDINATES,
    geoRadius: SEARCH_RADIUS,
    geoTypes: ['(regions)', '(cities)']
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService,
    private widgetSuggestionService: WidgetSuggestionService
  ) { }

  ngOnInit(): void {
    this.populateFieldsValues();

    if (this.model.destinationUrl) {
      this.destinationUrl = this.model.destinationUrl;
    }

    this.handleStoredData();
  }

  search(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const destination = this.destination;

    if (!destination) {
      this.alertMessage('Please enter a destination.');
    } else if (!this.ticketsEntity) {
      this.alertMessage('Please enter parks, artists, venues, events.');
    } else {
      this.dispatchWidgetData(destination);
      const ticketsPreset = this.activatedRoute.snapshot.queryParams;
      this.buttonClick.emit({ url: this.destinationUrl });
      void this.router.navigate(['/offers'], {
        relativeTo: this.activatedRoute,
        queryParams: { ...ticketsPreset, c: `${TICKETS_CATEGORY}`, tw_city_zip: destination, tw_q: this.ticketsEntity }
      });
    }
  }

  autoCompleteCallback(event: any): void {
    const { data } = event;
    this.destination = data.description;
    this.useDefaultDestination = false;
  }

  handleAddressChange(destination: string): void {
    this.destination = destination;
    this.useDefaultDestination = false;
  }

  onDestinationChange(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLTextAreaElement;
    this.destination = target.value;
    this.useDefaultDestination = true;
  }

  updatePlaceName(value: string): void {
    this.destination = value;
    this.useDefaultDestination = true;
  }

  onTicketsEntityChange(userInput: string): void {
    this.ticketsEntity = userInput;
  }

  private dispatchWidgetData(destination = this.destination): void {
    this.sessionStorageService.removeGroupOfKeys('_mini');
    const data = {
      destination,
      ticketsEntity: this.ticketsEntity
    };
    this.sessionStorageService.setItem(TICKETS_MINI, data);
    void this.widgetSuggestionService.addSuggestedWidgets({
      data,
      type: TICKETS_MINI,
      page_url: window.location.href,
      destination_url: this.getDestinationUrl(destination)
    });
  }

  private getDestinationUrl(destination: string): string {
    return `${window.location.href}/offers?c=${TICKETS_CATEGORY}&tw_city_zip=${encodeURIComponent(destination)}&tw_q=${encodeURIComponent(this.ticketsEntity)}`;
  }

  private handleStoredData(): void {
    const parsedTicketsWidgetData = this.sessionStorageService.getItem<any>(TICKETS_MINI);

    if (parsedTicketsWidgetData) {
      if (parsedTicketsWidgetData.destination) {
        this.autocompleteSettings.inputString = parsedTicketsWidgetData.destination;
        this.autocompleteOptions.inputString = parsedTicketsWidgetData.destination;
        this.destination = parsedTicketsWidgetData.destination;
      }

      if (parsedTicketsWidgetData.ticketsEntity) {
        this.ticketsEntity = parsedTicketsWidgetData.ticketsEntity;
      }
    }
  }

  private alertMessage(message: string): void {
    this.modalService.open(ModalAlertComponent, { size: 'sm', centered: true }).componentInstance.content = { text: message };
  }

  private populateFieldsValues(): void {
    const ticketsPreset = this.activatedRoute.snapshot.queryParams;

    this.ticketsEntity = ticketsPreset.tw_q || this.ticketsEntity;
    this.destination = ticketsPreset.tw_city_zip || this.destination;
    this.autocompleteSettings = {
      ...this.autocompleteSettings,
      inputString: ticketsPreset.tw_city_zip || this.destination
    };
    this.autocompleteOptions.inputString = ticketsPreset.tw_city_zip || this.destination;
  }
}
