import { AuthService } from './auth.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KNOWN_QUERY_PARAMS } from '@app/shared/services/known-user.service';
import { RetroactiveUpdateService } from '@app/shared/services/retroactive-update/retroactive-update.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DLKAccessService } from './dlk-access/dlk-access.service';
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthService,
    private readonly retroactiveUpdateService: RetroactiveUpdateService,
    private readonly dlkAccessService: DLKAccessService,
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap(() => { }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
                // Workaround for sign-out auto-redirecting to sign-in
                const authenticated = this.auth.isAuthenticated_BypassTokenHarvest();
                if (authenticated) {
                  this.auth.setUnauthenticated([
                    KNOWN_QUERY_PARAMS.KNOWN_USER_TYPE_KEY,
                    KNOWN_QUERY_PARAMS.KNOWN_USER_GUID_KEY,
                    KNOWN_QUERY_PARAMS.KNOWN_USER_CHILD_GUID_KEY,
                    KNOWN_QUERY_PARAMS.KNOWN_USER_CHILD_DLK_KEY,
                    KNOWN_QUERY_PARAMS.KNOWN_USER_EMAIL_KEY,
                    KNOWN_QUERY_PARAMS.KNOWN_SKIP_PASSWORD,
                    KNOWN_QUERY_PARAMS.KNOWN_SKIP_CONFIRM,
                    KNOWN_QUERY_PARAMS.KNOWN_USER_EMAIL_CONTACT_KEY
                  ]);

                  const isRetroactiveEmailUpdate = this.retroactiveUpdateService.getIsRetroactiveEmailUpdate();
                  const populateParams = this.dlkAccessService.getPopulateParams();
                  this.auth.redirectToAuthorize(null, isRetroactiveEmailUpdate, populateParams);
                }
                break;
              case 403:
                // TODO: handle not allowed
                if (err.headers?.get('cf-mitigated')) {
                  // should be handled by cloudflare
                  return;
                }
                console.error(`Request not allowed: ${request.url}`);
                break;
            }
          }
        })
      );
  }
}
