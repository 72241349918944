import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { ClickOutService } from '@shared/services/click-out.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { ConfigService } from '@shared/services/config.service';
import { CustomWidgetComponent } from '../../custom-widget/custom-widget.component';
import { ImageUrlPipeOptions } from 'g3-common-ui';
import { UrlService } from '@app/shared/services/url.service';
import { W_TRUE_CAR } from '@app/widgets/constants/widget-headers.constant';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeEventNameEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { Ad } from '@app/shared/models/ad.model';
import { ImageHandlerService } from '@shared/services/image-handler/image-handler.service';

const backgroundOptions = { w: 340, h: 438, alternate: false };

@Component({
  selector: 'app-truecar',
  templateUrl: './truecar.component.html',
  styleUrls: ['./truecar.component.less']
})
export class TrueCarComponent implements CustomWidgetComponent, OnInit {

  @Output() public clickOut = new EventEmitter<ClickOutModel>();

  public model: CustomWidgetModel;
  public groupTabIndex: number;
  public header: string;
  public logoUrl: string;
  public backgroundImage: string;
  public isEagerLoading: boolean;

  constructor(
    public imageHandlerService: ImageHandlerService,
    private analyticsService: AnalyticsService,
    private clickOutService: ClickOutService,
    private urlService: UrlService,
    public configService: ConfigService,
    public adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {
  }

  public ngOnInit(): void {
    this.initWidgetDynamicData();
    try {
      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel('widget-load', {
          name: 'true-car'
        }),
        new AnalyticsGAEventModel('widget-load', { category: 'true-car' })
      ]);
    } catch { }
  }

  public initWidgetDynamicData(): void {
    this.header = this.model && this.model.header ? this.model.header : 'Save on Your Next Car';
    this.logoUrl = this.model && this.model.logoUrl ? this.model.logoUrl : '/assets/TrueCar Logo_Square.jpg';
    this.backgroundImage = this.model && this.model.imageUrl ? this.model.imageUrl : this.getWidgetConfigImage();
  }

  public async onButtonClick(data: { url: string; type: string }): Promise<void> {
    const clickOut = 'external';

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-click', {
        name: 'true-car',
        action: data.type,
        click_out: clickOut
      }),
      new AnalyticsGAEventModel('widget-click', { category: 'true-car', label: `${data.type} | ${clickOut}` })
    ]);

    await this.handleClickOut(data.url);
    await this.sendAdobeAnalytics();
  }

  public getWidgetConfigImage(): string {
    const widgetConfig = this.configService.getWidgetConfig('truecar');
    return widgetConfig ? widgetConfig.img_url : '';
  }

  public getBackgroundOptions(): ImageUrlPipeOptions {
    return backgroundOptions;
  }

  private async handleClickOut(destinationUrl = ''): Promise<void> {
    const isExternalUrl = this.urlService.isExternalUrl(destinationUrl);
    if (isExternalUrl) {
      await this.clickOutService.clickOut(destinationUrl, '', {
        data: { logo: this.model.logoUrl, brand: W_TRUE_CAR, guid: this.model.id }
      }, true);
    } else {
      window.location.href = destinationUrl;
    }
  }

  private async sendAdobeAnalytics(): Promise<void> {
    const ad = new Ad({
      guid: this.model.guid,
      isEbg: this.model.isEbg,
      content: [{ key: 'header', value: this.model.header }],
      destinationUrl: this.model.destinationUrl,
      brand_name: this.model.brandName,
      catCodeTagIds: this.model.catCodeTagIds,
    });
    this.adobeAnalyticsEventsService.sendWidgetClick({
      event_name: AdobeEventNameEnum.WIDGET,
      event_type: this.adobeAnalyticsEventsService.getClickOutValueForWidget(ad, this.model.widgetId),
      properties: await this.adobeAnalyticsEventsService.getWidgetAdobeData(ad, 'offer', { size: 'large' })
    });
  }
}
