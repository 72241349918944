import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DLKAccessService } from './dlk-access/dlk-access.service';

@Injectable()
export class AuthGuardService {

  constructor(
    public readonly auth: AuthService,
    public readonly router: Router,
    public readonly dlkAccessService: DLKAccessService,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const newSubdomain = this.auth.getMovedToSubdomain();
    const isAuthenticated = await this.auth.isAuthenticated();
    const isDLKFlow = state.url.toLowerCase().includes('dlk') || this.dlkAccessService.DLKValue;

    // ? Is handled by DLK auto-login
    if (isDLKFlow) {
      return true;
    }

    if (!isAuthenticated) {

      if (state.url.startsWith('/offers/')) {
        if (isDLKFlow) {
          this.auth.redirectToAuthorize();

          return false;
        }

        this.auth.redirectToAuthorize();

        return false;
      }

      if (newSubdomain) {
        this.auth.redirectToNewSubdomain(false, newSubdomain);
        return false;
      }

      this.auth.redirectToAuthorize('/', false, this.dlkAccessService.getPopulateParams());

      return false;
    }

    // for controlpanel subdomain all links should start with /controlpanel prefix
    if (this.auth.getSubdomain() === 'controlpanel'
      && !(/^\/controlpanel/.test(state.url))) {
      window.location.href = window.location.origin + '/controlpanel';
      return false;
    }

    if (newSubdomain) {
      this.auth.redirectToNewSubdomain(true, newSubdomain);
      return false;
    }

    let requiredPermissions = [];
    if (route) {

      // if we can define current component from route
      // try to use permission from this item
      if (route.component) {
        if (route.data.permissions) {
          requiredPermissions = route.data.permissions;
        }
        // try to use permission from first child item
        // TODO: need to be defined more flexible and better logic here
      } else {
        if (route && route.firstChild && route.firstChild.data && route.firstChild.data.permissions) {
          requiredPermissions = route.firstChild.data.permissions;
        }
      }
    }

    // check if is defined permissions for this route
    if (Array.isArray(requiredPermissions) && requiredPermissions.length > 0) {
      const hasDefined = this.auth.permissionService.hasAnyDefined(requiredPermissions);
      if (!hasDefined) {
        void this.router.navigate(['/home']);
        return false;
      }
    }

    return true;
  }
}
