import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AutoLoginCompleteTypes, KnownUserParams } from '@app/core/auth/services/dlk-access/dlk-access.enum';

const AUTO_LOGIN_QUERY_PARAMS = [
  'iframe',
  KnownUserParams.email,
  KnownUserParams.childGuid,
  KnownUserParams.known,
  AutoLoginCompleteTypes.failed,
  AutoLoginCompleteTypes.success
];

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard {
  public constructor(
    private readonly router: Router
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const hasValidAutoLoginParam = AUTO_LOGIN_QUERY_PARAMS.some(param => state.url.toLowerCase().includes(param + '='));

    if (hasValidAutoLoginParam) {
      return true;
    }

    await this.router.navigate(['/home']);
  }
}
