import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import isEqual from 'lodash-es/isEqual';
import { merge, Subject, take } from 'rxjs';

interface Attributes {
  key: string;
  value: unknown;
}

interface ModalInput {
  /* eslint-disable-next-line typescriptESlintPlugin/no-explicit-any*/
  component: any;
  attributes?: Attributes[];
  options: NgbModalOptions;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public activeModalSubject = new Subject<NgbModalRef>();

  private activeModal: NgbModalRef;
  private modalQueue: ModalInput[] = [];
  private openedModal: ModalInput;

  constructor(
    private modalService: NgbModal
  ) { }

  public createModal(modalData: ModalInput): void {
    if (this.activeModal || this.modalService.hasOpenModals()) {
      this.addModalToQueue(modalData);
    } else {
      this.openModal(modalData);
      this.openedModal = modalData;
    }
  }

  public closeModal(reason: 'close' | 'dismiss' = 'close'): void {
    if (reason === 'close') {
      this.activeModal.close(true);
    } else {
      this.activeModal.dismiss();
    }
  }

  public hasOpenModal(): boolean {
    return Boolean(this.activeModal || this.modalQueue.length > 0);
  }

  public openModal({ component, attributes, options }: ModalInput): NgbModalRef {
    this.activeModal = this.modalService.open(component, options);

    if (attributes && attributes.length > 0) {
      attributes.forEach(attr => {
        this.activeModal.componentInstance[attr.key] = attr.value;
      });
    }
    this.activeModal.componentInstance.instancedComponentName = component.name;
    merge(
      this.activeModal.closed,
      this.activeModal.dismissed
    ).subscribe(() => this.processModalQueue());

    this.activeModalSubject.next(this.activeModal);

    return this.activeModal;
  }

  private processModalQueue(): void {
    if (this.modalQueue && this.modalQueue.length > 0) {
      this.openModal(this.modalQueue[0]);
      this.openedModal = this.modalQueue[0];
      this.modalQueue.shift();
    } else {
      this.activeModal = null;
      this.activeModalSubject.next(null);
    }
  }

  private addModalToQueue(modal: ModalInput): void {
    try {
      if (this.modalQueue.some(item => isEqual(item, modal)) || isEqual(modal, this.openedModal)) {
        return;
      }
      this.modalQueue.push(modal);
      return;
    } catch (e) { }
    this.modalQueue.push(modal);
  }
}
