/* eslint-disable typescriptESlintPlugin/no-explicit-any */
/* eslint-disable typescriptESlintPlugin/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { SideTabItem } from 'g3-common-ui';
import { switchMap, take, map, shareReplay, filter } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';

import { UserModel } from '@app/core/auth/services/user.model';
import { ConfigService } from '@shared/services/config.service';
import { G3analyticsService } from '../g3analytics.service';
import { KnownUserService } from '../known-user.service';
import { COUNTRY_LAST_SESSION_KEY } from '@app/shared/constants/profile.constants';
import { AccountConfirmationStep } from '../account-confirmation.interface';
import { EncryptedEmailResponse, ProfileNavItemsResponse, ProfileResponse, ShoppingCartResponse } from './profile.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profile: BehaviorSubject<UserModel> = new BehaviorSubject({} as UserModel);
  public readonly profileData$: Observable<UserModel> = this.profile.asObservable().pipe(
    filter(profile => !!profile.guid),
    shareReplay(1), // ensure that observable is 'hot'
  );
  public readonly profileDataUnfiltered$: Observable<UserModel> = this.profile.asObservable().pipe(
    shareReplay(1), // ensure that observable is 'hot'
  );
  public forceNpsModal = false;
  private sideTabItems: ReplaySubject<SideTabItem[]> = new ReplaySubject();
  public sideTabItems$ = this.sideTabItems.asObservable();

  constructor(
    private readonly http: HttpClient,
    private configService: ConfigService,
    private g3AnalyticsService: G3analyticsService,
    private knownUserService: KnownUserService
  ) {}

  getConfig(): ConfigService {
    return this.configService;
  }

  setData(profile: UserModel): void {
    const userProfile = { ...this.profile.getValue(), ...profile };
    const sessionCountryLast = localStorage.getItem(COUNTRY_LAST_SESSION_KEY);
    userProfile.countryLast = sessionCountryLast
      ? sessionCountryLast
      : profile.countryLast;
    if (userProfile.countryLast) {
      this.g3AnalyticsService.updateCountry(userProfile.countryLast);
    }

    this.profile.next(userProfile);
  }

  getData(): UserModel {
    return this.profile.getValue();
  }

  getOption(option: string): string {
    const subjectValue = this.profile.getValue();
    return subjectValue[option] || '';
  }

  async saveZip(value: string): Promise<string[]> {
    return this.http
      .post<string[]>(`${environment.apiUrl}/api/my/zips`, { zipcode: value })
      .toPromise();
  }

  async saveLocation(lat: number, lng: number): Promise<any> {
    return this.http
      .post<string[]>(`${environment.apiUrl}/api/my/locations`, { lat, lng })
      .toPromise();
  }

  // eslint-disable-next-line typescriptESlintPlugin/explicit-module-boundary-types
  async updateSurvey(name, data): Promise<any> {
    return this.http
      .put(`${environment.apiUrl}/api/profile/surveys/${name}`, data)
      .toPromise();
  }

  // eslint-disable-next-line typescriptESlintPlugin/explicit-module-boundary-types
  async updateProfile(data): Promise<any> {
    return this.http
      .patch(`${environment.apiUrl}/api/profile`, data)
      .toPromise();
  }

  setForceNpsModal(searchString: string): boolean {
    this.forceNpsModal = searchString.includes('?modal=nps');
    return this.forceNpsModal;
  }

  getProfile(contactEmailHash = ''): Observable<ProfileResponse> {
    const query = [];
    if (this.knownUserService.knownUserQueryParams) {
      query.push(this.knownUserService.knownUserQueryParams);
    }

    if (this.forceNpsModal) {
      query.push('modal=nps');
    }

    if (contactEmailHash && !this.knownUserService.knowUserType) {
      query.push(`contactEmailHash=${contactEmailHash}`);
    }

    const queryStr: string = query.join('&') || '';
    return this.http.get<ProfileResponse>(`${environment.apiUrl}/api/profile${queryStr ? '?' : ''}${queryStr}`);
  }

  getCartCount<T>(): Observable<ShoppingCartResponse<T>> {
    return this.http.get<ShoppingCartResponse<T>>(
      `${environment.apiUrl}/api/profile/cart`
    );
  }

  /**
   * Refresh the user profile
   *
   * @param lazy Load only if profile hasn't been loaded before
   * @returns Promise to reload the user profile
   */
  async refreshProfile(): Promise<UserModel> {
    return firstValueFrom(this.getProfile().pipe(
      take(1),
      switchMap(profile => {
        this.setData(new UserModel(profile));

        return of(this.getData());
      })
    ));
  }

  async deleteProfile(): Promise<{ is_deleted: boolean }> {
    let body = {};
    if (this.knownUserService.knowUserGuid) {
      body = { known_user_guid: this.knownUserService.knowUserGuid };
    }
    return this.http
      .put<{ is_deleted: boolean }>(`${environment.apiUrl}/api/profile/delete`, body)
      .toPromise();
  }

  public async validateNewEmail(email: string): Promise<{ isUpdateAllowed: boolean }> {
    const body = {
      email,
      verifyOnly: true
    };

    return this.http
      .put<{ isUpdateAllowed: boolean }>(`${environment.apiUrl}/api/profile/email`, body)
      .toPromise();
  }

  public async updateEmail(email: string): Promise<void> {
    const body = { email };

    return this.http
      .put<unknown>(`${environment.apiUrl}/api/profile/email`, body)
      .toPromise()
      .then(async () => {
        await this.refreshProfile();
      });
  }

  async updateContactEmail(email: string, oldEmail: string): Promise<any> {
    return this.http
      .put(`${environment.apiUrl}/api/profile/email-contact`, {
        email,
        oldEmail
      })
      .toPromise()
      .then(data => {
        const profileData = this.profile.getValue();
        profileData.changeContactEmail = {
          email,
          isExpired: false
        };
        this.profile.next(profileData);

        return data;
      });
  }

  async updatePassword(passwordOld: string, passwordNew: string): Promise<any> {
    return this.http
      .put(`${environment.apiUrl}/api/profile/password`, {
        current_password: passwordOld,
        new_password: passwordNew
      })
      .toPromise();
  }

  async createPassword(password: string): Promise<any> {
    return this.http.post(`${environment.apiUrl}/api/profile/password`, { password }).toPromise();
  }

  async cancelChangeEmail(): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/api/profile/confirm/change-email`, {})
      .toPromise()
      .then(data => {
        const profileData = this.profile.getValue();
        profileData.changeEmail = {
          email: '',
          isExpired: false
        };
        this.profile.next(profileData);

        return data;
      });
  }

  async cancelChangeContactEmail(): Promise<any> {
    const cancelUrl = `${environment.apiUrl}/api/profile/confirm/change-email-contact`;
    return this.http
      .delete<any>(cancelUrl, {})
      .toPromise()
      .then(data => {
        const profileData = this.profile.getValue();
        profileData.changeContactEmail = {
          email: '',
          isExpired: false
        };
        this.profile.next(profileData);

        return data;
      });
  }

  async updateCurrentCountry(
    countryCode: string,
    updateSession = true
  ): Promise<any> {
    if (updateSession) {
      this.g3AnalyticsService.updateCountry(countryCode);
    }

    if (localStorage.getItem(COUNTRY_LAST_SESSION_KEY)) {
      localStorage.setItem(COUNTRY_LAST_SESSION_KEY, countryCode);

      await this.http
        .put(`/api/my/country-last?country_code=${countryCode.toUpperCase()}`, {})
        .toPromise();

      return null;
    }

    return this.http
      .put(`/api/my/country-last?country_code=${countryCode.toUpperCase()}`, {})
      .toPromise();
  }

  getCountryUrl(countryCode: string): string {
    let countryUrl = '';
    const countryOptions = this.configService.getOption<any>(
      'country_options',
      []
    );
    const selectedCountryOptions = (countryOptions || []).find(
      i => i.country_code === countryCode
    );

    if (selectedCountryOptions) {
      countryUrl = selectedCountryOptions.country_url;
    }
    return countryUrl;
  }

  async clearUserCountryLast(): Promise<any> {
    await this.http
      .put('/api/my/country-last?country_code=NONE', {})
      .toPromise();
  }

  getHomepageRedirectUrl(countryCode: string): string {
    let homepageRedirectUrl = '';

    const countryOptions = this.configService.getOption<any>(
      'country_options',
      []
    );
    const selectedCountryOptions = (countryOptions || []).find(
      i => i.country_code === countryCode
    );

    if (selectedCountryOptions) {
      homepageRedirectUrl = selectedCountryOptions.home_redirect_url;
    }
    return homepageRedirectUrl;
  }

  async changeCountry(code: string): Promise<any> { }

  updateDismissedDecisions(
    decisionGuid: string,
    expiredDate: { amount: number; units: string } = null
  ): Observable<string[]> {
    const body: { expired_date?: { amount: number; units: string }; known_user_guid?: string } = expiredDate
      ? { expired_date: expiredDate }
      : {};

    const knownUserGuid = this.knownUserService.knowUserGuid;
    if (knownUserGuid) {
      body.known_user_guid = knownUserGuid;
    }

    return this.http.put<string[]>(
      `${environment.apiUrl}/api/profile/decisions/${decisionGuid}`,
      body
    );
  }

  isExternalAuthentication(value: string): boolean {
    return value === 'google';
  }

  isGuestOrKnownUser(): boolean {
    const user = this.getData();
    return (
      user && (!!user.isAllowSignOnNewAccount || !!user.isSignedInAsKnownUser)
    );
  }

  isGuestUser(): boolean {
    const user = this.getData();
    return user && !!user.isAllowSignOnNewAccount;
  }

  isKnownUser(): boolean {
    const user = this.getData();
    return user && !!user.isSignedInAsKnownUser;
  }

  handleCountryLastByUserRole(): void {
    if (!localStorage.getItem(COUNTRY_LAST_SESSION_KEY)) {
      this.profile.pipe(take(2)).subscribe(i => {
        if (i.isAllowSignOnNewAccount || i.isSignedInAsKnownUser) {
          localStorage.setItem(COUNTRY_LAST_SESSION_KEY, i.countryLast);
        } else {
          localStorage.removeItem(COUNTRY_LAST_SESSION_KEY);
        }
      });
    }
  }

  getUserEmail(profile: UserModel): string {
    const isNotSSo = this.configService
      ? !this.configService.getConfig().is_sso
      : true;

    if (profile.email && isNotSSo) {
      return profile.email;
    }

    // Use secondary email if primary is not valid
    return profile.emailContact;
  }

  getAllowProfilePrompts$(): Observable<AccountConfirmationStep[]> {
    const allowPrompts = this.configService.getOption<string[]>('allow_prompts', []);
    return this.profile.pipe(
      map(user =>
        allowPrompts.reduce((acc: AccountConfirmationStep[], curr: string) => {
          if (curr === 'zip' && !user.zipCode) {
            acc.push(AccountConfirmationStep.ProfilePromptZip);
          }
          // TODO: uncomment when profile phone prompt is ready
          // if (curr === 'phone' && !user.confirmedPhone?.number) {
          //   acc.push(AccountConfirmationStep.ProfilePromptPhone);
          // }
          return acc;
        }, [])
      )
    );
  }

  getProfileNavItems(): Observable<SideTabItem[]> {
    return this.http.get<ProfileNavItemsResponse>(`${environment.apiUrl}/api/profile/externals/nav-items?sdcid=${this.configService.getSubdomain()}`)
      .pipe(
        map(response => response.result.map(i => ({
          title: i.profile_nav_text,
          name: i.profile_nav_name.replace('/', ''),
          externalLink: i.profile_nav_href?.startsWith('http') ? i.profile_nav_href : null,
          image: i.profile_nav_img_src.replace('/', ''),
          type: i.profile_nav_type,
          permissions: [i.profile_nav_permission],
          tabIndex: i.profile_nav_tab_index
        })))
      );
  }

  public setAvailableSideTabs(sideTabs: SideTabItem[]): void {
    this.sideTabItems.next(cloneDeep(sideTabs));
  }

  /**
   * Fetches current user's encrypted email
   *
   * @returns - Observable with current user's encrypted email. Null if user's email is not a valid email
   */
  getUserEncryptedEmail(skipEmailValidation = false): Observable<string | null> {
    const queryParts = [];
    if (this.knownUserService.knowUserGuid) {
      queryParts.push(`known_user_guid=${this.knownUserService.knowUserGuid}`);
    }
    if (this.knownUserService.knowUserChildGuid) {
      queryParts.push(`known_user_child_guid=${this.knownUserService.knowUserChildGuid}`);
    }
    if (skipEmailValidation) {
      queryParts.push('skip_email_validation=true');
    }
    const query = queryParts.join('&');

    return this.http.get<EncryptedEmailResponse>(`${environment.apiUrl}/api/profile/encrypted-email${query ? '?' + query : ''}`)
      .pipe(
        take(1),
        map(res => res.encrypted_email)
      );
  }

  async completeAdobeSignUpEvent(): Promise<void> {
    const queryParts = [];

    if (this.knownUserService.knowUserGuid) {
      queryParts.push(`known_user_guid=${this.knownUserService.knowUserGuid}`);
    }
    if (this.knownUserService.knowUserChildGuid) {
      queryParts.push(`known_user_child_guid=${this.knownUserService.knowUserChildGuid}`);
    }

    const query = queryParts.join('&');
    return firstValueFrom(this.http.patch<void>(
      `${environment.apiUrl}/api/profile/complete-adobe-sign-up${query ? '?' + query : ''}`, {}
    ));
  }
}
