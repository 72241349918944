<div [attr.id]="anchorLink"
     class="cta-container"
     *ngIf="ctaBlock">
  <div class="cta-decision cta-decision__small"
       [appAutoTabIndex]="groupTabIndex + 3"
       (click)="handleCtaClick()">

    <div class="cta-decision__background">
      <div class="background-image">
        <img
          [ngSrc]="backgroundUrl"
          [loading]="imageLoading"
          (error)="imageHandlerService.swapImage($event.target)"
          [placeholder]="imageHandlerService.getDefaultPlaceholder"
          alt=""
          class="background-cta-image"
          fill>
      </div>
    </div>

    <div class="cta-decision__text-container">

      <h3 class="cta-decision__header">{{ ctaBlock.header }}</h3>
      <p class="cta-decision__text">{{ ctaBlock.body }}</p>
      <button class="btn btn-primary"
              *ngIf="ctaButtonText"
              [appAutoTabIndex]="groupTabIndex + 4">
        {{ ctaButtonText }}
      </button>

    </div>
    <div class="foreground-image">
      <img
        [ngSrc]="foregroundUrl"
        [loading]="imageLoading"
        (error)="imageHandlerService.swapImage($event.target)"
        [placeholder]="imageHandlerService.getDefaultPlaceholder"
        class="foreground-cta-image"
        alt=""
        fill>
    </div>
  </div>

  <div class="cta-decision cta-decision__large"
       [appAutoTabIndex]="groupTabIndex + 3"
       (click)="handleCtaClick()"
       [class.wide-endpoint]="!!wideContainerClass">
    <div class="background-image">
      <img
        [ngSrc]="backgroundUrl"
        [loading]="imageLoading"
        (error)="imageHandlerService.swapImage($event.target)"
        [placeholder]="imageHandlerService.getDefaultPlaceholder"
        alt=""
        class="background-cta-image"
        fill>
    </div>

    <div class="cta-decision__text-container">

      <h3 class="cta-decision__header">{{ctaBlock.header}}</h3>
      <p class="cta-decision__text" [innerHTML]="(ctaBlock.body || '') | safe"></p>
      <button class="btn btn-primary"
              *ngIf="ctaButtonText"
              [appAutoTabIndex]="groupTabIndex + 4">
        {{ ctaButtonText }}
      </button>

    </div>

    <div *ngIf="ctaBlock?.image_url"
         class="foreground-image"
         [class.foreground-image-mask]="ctaElementService.isImageSvg(ctaBlock?.image_url) && !ctaElementService.isMSOrFirefoxBrowser()">
      <img
        [ngSrc]="foregroundUrl"
        class="foreground-cta-image"
        [loading]="imageLoading"
        (error)="imageHandlerService.swapImage($event.target)"
        [placeholder]="imageHandlerService.getDefaultPlaceholder"
        alt=""
        fill>
    </div>

  </div>

</div>
