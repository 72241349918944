<div class="widget car-widget widget-b">
  <div class="widget-image-container">

    <img
      [ngSrc]="backgroundImage | imageUrl : getBackgroundOptions() : false"
      [priority]="isEagerLoading"
      (error)="imageHandlerService.swapImage($event.target)"
      [placeholder]="imageHandlerService.getDefaultPlaceholder"
      alt=""
      class="background-widget-image"
      fill>

    <div class="logo-wrapper-b">
      <img [ngSrc]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           width="70"
           height="70"
           [priority]="isEagerLoading"
           alt="taw"
           class="logo" />
    </div>
  </div>

  <div class="relative widget-form-container">
    <div class="logo-wrapper-b-mobile">
      <img [ngSrc]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           alt="taw"
           width="70"
           height="70"
           [priority]="isEagerLoading"
           class="logo" />
    </div>

    <div class="flex-container correct-margin flex-row">
      <div class="image">
        <img [ngSrc]="'/assets/icons/ic-car.svg'"
             (error)="imageHandlerService.swapImage($event.target)"
             [placeholder]="imageHandlerService.getDefaultPlaceholder"
             height="30"
             width="30"
             class="ic_car"
             alt="">
      </div>
      <div class="header-text">
        <h3>{{ header }}</h3>
      </div>
    </div>
    <app-truecar-form [model]="model"
                      widgetClass="large-widget"
                      (buttonClick)="onButtonClick($event)">
    </app-truecar-form>
    <div class="logo-wrapper">
      <img [ngSrc]="'/assets/truecar.png'"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           width="107"
           height="107"
           alt="true-car"
           class="logo" />
    </div>
  </div>
</div>
