import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { HomepageAdsService } from '@shared/services/homepage-ads.service';
import { Subscription } from 'rxjs';
import { TAW_HOTELS_LARGE, TRUECAR_LARGE } from '@app/widgets/constants/widget-ids.constant';
import { WidgetModel, WidgetType } from '@shared/models/widget.model';
import { WidgetsAdContent } from '@app/zones/interfaces/widgets.interface';
import { WidgetTypeAware } from '@widgets/widget-type-aware.decorator';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { ZoneWidgetsService } from '@app/zones/services/zone-widgets.service';
import { Ad } from '@app/shared/models/ad.model';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeWidgetImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';

@Component({
  selector: 'app-widgets-large',
  templateUrl: './widgets-large.component.html',
  styleUrls: ['./widgets-large.component.less']
})

@WidgetTypeAware
export class WidgetsLargeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: ZoneSectionElement;
  @Input() groupTabIndex: number;
  @Input() wideContainerClass = false;
  @Input() isEagerLoading: boolean;

  @ViewChildren('widgetElems') widgetElems: QueryList<unknown>;

  widgets: Array<WidgetModel> = [];
  widgetType;
  anchorLink: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private zoneWidgetsService: ZoneWidgetsService,
    private cdr: ChangeDetectorRef,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {
    this.widgetType = WidgetType;
  }

  public ngOnInit(): void {
    const allowedWidgetIds = [TRUECAR_LARGE, TAW_HOTELS_LARGE];
    const adContent = this.zoneWidgetsService.getWidgetsAdContent(this.data, allowedWidgetIds);
    this.widgets = this.getWidgets(adContent);

    if (this.data && this.data.name) {
      const sectionName = `s_${this.data.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_widgets_large';
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    this.cdr.detectChanges();
    if (this.data?.ads?.length) {
      const impressions = await Promise.all(this.data.ads?.map(
        async (ad: Ad, index) => this.adobeAnalyticsEventsService.getWidgetAdobeData(ad, `offer${index + 1}`, {
          size: 'large',
          zone: this.data.name
        })
      ));
      const adobeEventImpressionsData: AdobeWidgetImpresssionsEvent = {
        event_name: AdobeEventNameEnum.WIDGET,
        event_type: AdobeEventTypeEnum.IMPRESSION,
        properties: { impressions }
      };
      this.adobeAnalyticsEventsService.sendWidgetImpressions(adobeEventImpressionsData, AdobeEventLocation.Homepage);
    }
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  getWidgets(adContent: WidgetsAdContent[]): WidgetModel[] {
    const widgetDefaultData = {
      widgetType: 300,
    };
    return (adContent || []).map(widget => ({ ...widget, ...widgetDefaultData }));
  }

  showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.data.is_ebg);
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public trackById(index: number, item: WidgetModel): number {
    return item.widgetId;
  }

}
