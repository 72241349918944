/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad } from '@app/shared/models/ad.model';
import { AdCtaClick } from '@shared/components/ad-item/ad-item.component';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { PRODUCT_CARD } from '@app/zones/services/zones.service';
import { Router } from '@angular/router';
import { NavigationService } from '@app/shared/services/navigation.service';
import { PaylogixService } from '@app/shared/services/paylogix/paylogix.service';
import { ClickOutService } from '@app/shared/services/click-out.service';
import { AccountConfirmationService } from '@app/shared/services/account-confirmation.service';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';

@Component({
  selector: 'app-special-offers-item',
  templateUrl: './special-offers-item.component.html',
  styleUrls: ['./special-offers-item.component.less']
})
export class SpecialOffersItemComponent implements OnInit, AfterViewInit {

  @Input() ads: Ad[] = [];
  @Input() itemName = 'new';
  @Input() itemTitle = 'Newest';
  @Input() linkTitle = 'Newest';
  @Input() linkUrl = '';
  @Input() itemIcon: string;
  @Input() favoriteFromEventName: string;
  @Input() isEnrollment = false;
  @Input() disableShowAll = false;
  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Input() groupTabIndex: number;
  @Input() itemCategoryGuid: string = null;
  @Input() shouldAlignItems: boolean;
  @Input() isAllowDisableHover = true;
  @Input() ctaIsEbgLink = false;
  @Input() isStaggered = false;

  public anchorLink: string;
  public prevGuidsSentToAdobe = '';

  private groupEventCategoriesDictionary = new Map([
    ['viewed', 'recently-viewed-offers-view-all-click'],
    ['favorites', 'my-favorites-offers-view-all-click'],
    ['limited', 'lto-offers-view-all-click'],
    ['new', 'newest-offers-view-all-click']
  ]);

  constructor(
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private router: Router,
    private directClickOutService: DirectClickOutService,
    private homepageAdsService: HomepageAdsService,
    private navigationService: NavigationService,
    private paylogixService: PaylogixService,
    private clickOutService: ClickOutService,
    private accountConfirmationService: AccountConfirmationService
  ) { }

  ngOnInit(): void {
    this.anchorLink = this.homepageAdsService.getAnchorLink(`s_${this.itemTitle}`);
  }

  ngAfterViewInit(): void {
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  // * Disabled by G3PROJ-2529
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.ads) {
  //     const ads = this.getAds();
  //     const guids = ads.map((ad: Ad) => ad.guid).join(',');
  //     if (this.prevGuidsSentToAdobe !== guids && ads?.length) {
  //       this.prevGuidsSentToAdobe = guids;
  //       this.sendAdobeImpressions(ads);
  //     }
  //   }
  // }

  sendAdobeImpressions(ads: Ad[]): void {
    const adobeEvent: AdobeImpresssionsEvent = {
      event_name: AdobeEventNameEnum.CARD,
      event_type: AdobeEventTypeEnum.IMPRESSION,
      properties: {
        impressions: ads.map((ad, index) => this.adobeAnalyticsEventsService.getOfferAdobeData(ad, 'standard', `offer${index + 1}`, this.zoneName, this.isEnrollment))
      }
    };

    if (this.isEnrollment) {
      void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent, AdobeEventLocation.Homepage);
      return;
    }

    void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent);
  }

  get shortTitle(): string {
    return this.isEnrollment
      ? 'view all'
      : `All ${this.itemName}`;
  }

  get fullText(): string {
    return `All ${this.linkTitle}`;
  }

  get alignItems(): boolean {
    if (!this.shouldAlignItems) {
      return false;
    }

    const ads = this.getAds();

    return ads && ads.length < 4;
  }

  get zoneName(): string {
    if (this.isEnrollment) {
      return 'Homepage Takeover';
    } else if (this.itemName === 'viewed') {
      return 'Recently Viewed';
    } else if (this.itemName === 'related') {
      return 'Related Offers';
    }
  }

  async handleEnrollmentViewAllClick($event: MouseEvent): Promise<void> {
    $event.stopPropagation();
    $event.preventDefault();

    const isPaylogixTokenPresent = this.linkUrl.includes(this.paylogixService.paylogixUrlToken);
    if (this.paylogixService.marketplaceHasPaylogixId() && isPaylogixTokenPresent) {
      if (this.paylogixService.userHasPaylogixId()) {
        this.clickOutService.handleHomepageTakeoverCtaClickOut();
      } else {
        await this.paylogixService.activateCompleteInfoModal();
        this.accountConfirmationService.openPaylogixProfileModal('Homepage');
      }
    } else {
      this.directClickOutService.trackAnalyticsEvents('enrollment-click-all-benefits', this.itemTitle, '');
      this.navigationService.handleClick(this.linkUrl, this.ctaIsEbgLink);
    }
  }

  handleShowAllClick($event): void {
    // TODO: this method is messy and should be refactored
    $event.stopPropagation();
    $event.preventDefault();
    let clickUrl: any;

    const eventCategory = this.groupEventCategoriesDictionary.has(this.itemName)
      ? this.groupEventCategoriesDictionary.get(this.itemName)
      : 'special-offers-view-all-click';

    this.directClickOutService.trackAnalyticsEvents(
      eventCategory,
      this.itemTitle,
      ''
    );

    if (this.itemCategoryGuid) {
      const params = { cat: this.itemCategoryGuid };
      void this.router.navigate(['/offers'], { queryParams: params });
    } else if (['favorites', 'viewed'].includes(this.itemName)) {
      clickUrl = `my-offers/${this.itemName}`;
      void this.router.navigate([clickUrl]);
    } else if (this.linkUrl && this.linkUrl !== '') {
      const urlSplit = this.linkUrl.split('?');
      clickUrl = urlSplit[0];
      let params = null;
      if (urlSplit.length >= 2) {
        const paramsString = urlSplit[1];
        params = JSON.parse('{"' + decodeURI(paramsString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      }
      void this.router.navigate([clickUrl], { queryParams: params });
    } else {
      clickUrl = `offers?specials=${this.itemName}`;
      const queryParams = { specials: this.itemName, bp_beta: 'universal' };
      void this.router.navigate(['/offers'], { queryParams });
    }
  }

  async onClickedCta(ad: Ad, index: number): Promise<void> {
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(ad, 'standard', this.zoneName, this.isEnrollment);
    await this.directClickOutService.adCards.handleAdClick(ad, 'card', null, null, null, null, this.isEnrollment, adobeInfo);
  }

  isProductCard(ad: Ad): boolean {
    return ad.class && ad.class === PRODUCT_CARD;
  }

  onClickedAdCta(data: AdCtaClick, index: number): void {
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(data.ad, 'standard', this.zoneName, this.isEnrollment);
    switch (data.source) {
      case 'cta':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta', null, '', null, null, this.isEnrollment, adobeInfo);
        break;
      case 'cta-zipcode':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta-zipcode', null, 'offer-card-with-zipcode-input', '', data.params, this.isEnrollment, adobeInfo);
        break;
      case 'paylogix-enrollment':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'paylogix-enrollment', null, '', null, null, true, adobeInfo);
        break;
    }
  }

  getAds(): any {
    if (this.ads.length) {
      const windowWidth = window.innerWidth;

      if (this.isWideContainer() && windowWidth >= 1472) {
        return this.ads;
      } else {
        return this.ads.slice(0, 4); // remove last element
      }
    }
  }

  isWideContainer(): boolean {
    return !!this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  specialOfferTrackBy(index, item): string {
    return item.id;
  }

  isStaggeredVariant(count: number): boolean {
    return this.isStaggered && this.getAds().length === count;
  }
}
