/* eslint-disable typescriptESlintPlugin/no-misused-promises */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AccountConfirmationService } from '../account-confirmation.service';
import { MregEntryUpdateResponse, MregNotification, MregResponse } from '@app/shared/interfaces/mreg-config.interface';
import { MREG_ENTRY_DATE } from '../../constants/mreg.constants';
import { SOURCE_ID } from '@app/shared/constants/source-id.constants';

interface EntryData {
  entries_updated: number;
  mreg_config_guids: MregEntryUpdateResponse;
}

@Injectable({ providedIn: 'root' })
export class MregService {

  public isMreg = false;
  public isEntryMode = false;
  public mregId = '';

  private notifications: MregNotification[] = [];

  public constructor(
    private readonly accountConfirmationService: AccountConfirmationService,
    private readonly http: HttpClient
  ) { }

  public async handleMregParam(mreg: string, knownUserGuid: string, isDailyEntry: boolean): Promise<void> {
    this.mregId = mreg;

    await this.registerEntry(mreg, knownUserGuid, isDailyEntry);
  }

  public async registerEntry(mregId: string, knownUserGuid: string, isDailyEntry: boolean): Promise<void> {
    const body: { knownUserGuid: string; sourceId?: string } = { knownUserGuid };

    const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
    if (sourceId) {
      body.sourceId = sourceId;
    }

    let mregData: Partial<MregResponse> = null;
    try {
      mregData = await this.http.post<Partial<MregResponse>>(`${environment.apiUrl}/api/mreg/entry/${mregId}`, body).toPromise();
      if (!mregData) {
        return;
      }
    } catch (err) {
      return;
    }

    if (mregData.guest_head) {
      await this.handleGuestNotification(mregData);
      return;
    }

    const notification = mregData.notification;
    if (notification) {
      await this.showMregEnteredNotification(notification, isDailyEntry);
    }

  }

  public async addDailyEntry(knownUserGuid?: string): Promise<void> {
    const body: { knownUserGuid?: string; sourceId?: string } = {};

    if (knownUserGuid) {
      body.knownUserGuid = knownUserGuid;
    }

    const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
    if (sourceId) {
      body.sourceId = sourceId;
    }

    try {
      const entryData = await this.http.put<EntryData>(`${environment.apiUrl}/api/mreg/daily-entry`, body)
        .pipe(tap((data) => {
          if (data) {
            localStorage.setItem(MREG_ENTRY_DATE, new Date().toISOString());
          }
        }))
        .toPromise();

      if (!entryData) {
        return;
      }
      await this.getMregNotificationData(entryData);
    } catch (error) {
      await this.accountConfirmationService.showAdditionalNotifications(true);
    }

  }

  public async getMregNotificationData(entryData: { mreg_config_guids: MregEntryUpdateResponse }): Promise<void> {
    const mregGuids = entryData.mreg_config_guids;

    if (!mregGuids || !mregGuids.entry.length) {
      return;
    }

    try {
      const body = { mregGuids: mregGuids.entry };
      const data = await this.http
        .post<{ icon: string; head: string; body: string; button: string; link_txt: string; link_url: string }[]>(`${environment.apiUrl}/api/mreg-config/notifications`, body)
        .toPromise();

      let notificationsData = [];
      if (data) {
        notificationsData = data.map(el => ({
          icon: el.icon || '',
          title: el.head || '',
          content: el.body || '',
          buttonTitle: el.button || '',
          link_txt: el.link_txt || '',
          link_url: el.link_url || '',
        }));
      }

      this.isEntryMode = true;

      // Show all notifications
      const notificationQueue = [...this.notifications, ...notificationsData];
      if (!notificationQueue && !notificationQueue.length) {
        return;
      }

      await this.addToQueue(notificationQueue);

    } catch (error) {
      if (this.notifications && this.notifications.length) {
        await this.addToQueue(this.notifications);
      }
    }
  }

  private async addToQueue(notificationQueue: MregNotification[] = []): Promise<void> {
    this.accountConfirmationService.notificationQueue = [...this.accountConfirmationService.notificationQueue, ...notificationQueue];
    await this.accountConfirmationService.showAdditionalNotifications();
  }

  private async handleGuestNotification(mregData: Partial<MregResponse>): Promise<void> {
    const notificationContent = {
      title: mregData.guest_head,
      content: mregData.guest_body,
      buttonTitle: mregData.guest_button,
      icon: mregData.icon,
      link_txt: mregData.link_txt,
      link_url: mregData.link_url
    };

    this.isMreg = true;
    this.accountConfirmationService.notificationQueue.push(notificationContent);
    await this.accountConfirmationService.showAdditionalNotifications();
  }

  private async showMregEnteredNotification(notification: { head: string; body: string; button: string; link_txt: string; link_url: string }, isDailyEntry: boolean): Promise<void> {
    this.isEntryMode = true;

    const messageContent = {
      title: notification.head || '',
      content: notification.body || '',
      buttonTitle: notification.button || '',
      link_txt: notification.link_txt,
      link_url: notification.link_url
    };

    this.accountConfirmationService.notificationQueue.push(messageContent);
    await this.accountConfirmationService.showAdditionalNotifications();
  }

}
