/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ProfileResponse } from '@app/shared/services/profile/profile.interfaces';
import { MarketingEmailTypeEnum } from '@app/subscription-center/enums/marketing-email.enum';

export class UserModel {
  public birthday: string;
  public changeEmail: {
    email: string;
    isExpired: boolean;
  };
  public changeContactEmail: {
    email: string;
    isExpired: boolean;
  };
  public countryLast: string;
  public favoriteOffers: string[];
  public dateFormat: string;
  public dismissedDecisions: {
    guid: string;
    expired_date: string;
  }[];
  public email: string;
  public emailContact: string;
  public externalAuthentication: string;
  public firstName: string;
  public funLifeEmail: string;
  public gender: string;
  public guid: string;
  public id?: string;
  public isAllowSignOnNewAccount: boolean;
  public isProfileFeature: boolean;
  public isSignedInAsKnownUser: boolean;
  public lastName: string;
  public lastSignInAt: string;
  public marketingEmail: MarketingEmailTypeEnum | string;
  public memberDate: string;
  public firstAccessDate: string;
  public passwordChangedAt: string;
  public status: string;
  public surveys: {
    name: string;
    question: string;
  }[];
  public userId: string;
  public vendorSettings: {
    paylogixParticipantId: string;
    paylogixPhone?: string;
    paylogixTokenHash?: string;
  };
  public zipCode: string;
  public unconfirmedPhone: {
    country_code: string;
    number: string;
  };

  public confirmedPhone: {
    country_code: string;
    number: string;
  };
  public allowParticipantId: boolean;
  public enroll_type?: string;
  public requireEmailUpdate?: boolean;
  public adobe_sign_up_event?: {
    status: string;
    timestamp: string;
  };

  constructor(data: ProfileResponse) {

    if (data.date_format) {
      this.dateFormat = data.date_format;
    }

    if (data.first_name) {
      this.firstName = data.first_name;
    }

    if (data.guid) {
      this.guid = data.guid;
    }

    if (data.last_name) {
      this.lastName = data.last_name;
    }

    if (data.email) {
      this.email = data.email;
    }

    if (data.email_contact) {
      this.emailContact = data.email_contact;
    }

    if (data.gender) {
      this.gender = data.gender;
    }

    if (data.member_date) {
      this.memberDate = data.member_date;
    }

    if (data.first_access_date) {
      this.firstAccessDate = data.first_access_date;
    } else if (data.firstAccessDate) {
      this.firstAccessDate = data.firstAccessDate;
    }

    if ('change_email' in data && Object.keys(data.change_email).length) {
      this.changeEmail = {
        email: data.change_email.email,
        isExpired: data.change_email.is_expired
      };
    }

    if (
      'change_email_contact' in data &&
      Object.keys(data.change_email_contact).length
    ) {
      this.changeContactEmail = {
        email: data.change_email_contact.email,
        isExpired: data.change_email_contact.is_expired
      };
    }

    if (data.id) {
      this.id = data.id;
    }

    if (data.surveys) {
      this.surveys = data.surveys;
    }

    if (data.dismissed_decisions) {
      this.dismissedDecisions = data.dismissed_decisions;
    }

    if (data.is_profile_feature) {
      this.isProfileFeature = data.is_profile_feature;
    }

    if (data.marketing_email) {
      this.marketingEmail = data.marketing_email;
    }

    if (data.external_authentication) {
      this.externalAuthentication = data.external_authentication;
    }

    if (data.last_sign_in_at) {
      this.lastSignInAt = data.last_sign_in_at;
    }

    if (data.password_changed_at) {
      this.passwordChangedAt = data.password_changed_at;
    }

    this.vendorSettings = {
      paylogixParticipantId: data.vendor_settings && data.vendor_settings.paylogix_participant_id || '',
      paylogixPhone: data.vendor_settings && data.vendor_settings.paylogix_phone || '',
      paylogixTokenHash: data.vendor_settings && data.vendor_settings.paylogix_token_hash || ''
    };

    this.birthday = data.birthday || '';
    this.zipCode = data.zip_code;
    this.userId = data.user_id || '';
    this.countryLast = data.country_last;
    this.favoriteOffers = data.favorite_offers || [];
    this.isAllowSignOnNewAccount = data.is_allow_sign_on_new_account;
    this.isSignedInAsKnownUser = data.is_signed_in_as_known_user;

    if (data.unconfirmed_phone) {
      this.unconfirmedPhone = data.unconfirmed_phone;
    }

    if (data.confirmed_phone) {
      this.confirmedPhone = data.confirmed_phone;
    }

    if (data.allow_participant_id) {
      this.allowParticipantId = data.allow_participant_id;
    }

    if (data.enroll_type) {
      this.enroll_type = data.enroll_type;
    }

    if (data.requireEmailUpdate) {
      this.requireEmailUpdate = data.requireEmailUpdate;
    }

    if (data.adobe_sign_up_event) {
      this.adobe_sign_up_event = data.adobe_sign_up_event;
    }
  }
}
