import { Component, OnInit } from '@angular/core';
import { DLKAccessService } from '../services/dlk-access/dlk-access.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AutoLoginCompleteTypes } from '../services/dlk-access/dlk-access.enum';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrl: './auto-login.component.less'
})
export class AutoLoginComponent implements OnInit {
  public constructor(
    private readonly dlkAccessService: DLKAccessService,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public async ngOnInit(): Promise<void> {
    const loggedIn = await this.authService.isAuthenticated();
    if (loggedIn) {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      this.dlkAccessService.redirectToSilentDLK(queryParams);
    } else {
      this.authService.redirectToAuthorize(`/auto-login?${AutoLoginCompleteTypes.success}=true`);
    }
  }
}
