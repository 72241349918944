import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { KnownUserTypes } from 'g3-common-ui';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { queryParams } from '@app/shared/helpers/query-string';
import { ProfileService } from '@shared/services/profile/profile.service';
import { firstValueFrom } from 'rxjs';

const CORPORATE_REDIRECT_TIMEOUT = 2500;

const availableDomains = [
  'beneplace.com',
  'workingadvantage.com',
  'ticketsatwork.com',
  'plumbenefits.com'
];

export interface EbgQueryParams {
  known_user_email?: string;
  user_guid?: string;
}

@Injectable()
export class CorporateRedirectService {

  public shouldRedirect = true;

  private profileService: ProfileService;

  constructor(
    private readonly http: HttpClient,
    private readonly knownUserService: KnownUserService
  ) { }

  public setProfileService(service: ProfileService): void {
    this.profileService = service;
  }

  public isAllowedCorporateRedirect(queryOptions: Params): boolean {
    return queryOptions && queryOptions['corpRedirectUri'] && this.isValidRedirectUrl(decodeURI(queryOptions['corpRedirectUri']));
  }

  public async corporateRedirect(url: string): Promise<void> {
    let redirectUrlParams = '';
    try {
      redirectUrlParams = await this.getRedirectUrlWithParams();
    } catch (error) {
      redirectUrlParams = await this.getRedirectUrlDefaultParams();
    }

    if (!redirectUrlParams) {
      return;
    }

    this.shouldRedirect = true;

    setTimeout(() => {
      if (this.shouldRedirect) {
        const newUrl = url.includes('?') ? `${url}&${redirectUrlParams}` : `${url}?${redirectUrlParams}`;
        window.location.replace(newUrl);
      }
    }, CORPORATE_REDIRECT_TIMEOUT);
  }

  public isValidRedirectUrl(url: string): boolean {
    if (!url) {
      return false;
    }

    return availableDomains.some(domain => {
      const { hostname } = new URL(url);

      return hostname.includes(domain);
    });
  }

  public async generateCorporateRedirectUrl(url: string): Promise<string> {
    let redirectUrlParams = '';
    try {
      redirectUrlParams = await this.getRedirectUrlWithParams();
    } catch (error) {
      redirectUrlParams = await this.getRedirectUrlDefaultParams();
    }
    return url.includes('?') ? `${url}&${redirectUrlParams}` : `${url}?${redirectUrlParams}`;
  }

  public async getRedirectUrlWithParams(): Promise<string> {
    const query: EbgQueryParams = {};
    if (this.knownUserService.knowUserType === KnownUserTypes.CHILD) {
      query.known_user_email = this.knownUserService.knowUserEmail;
    }

    const profileData = await firstValueFrom(this.profileService.profileData$);
    query.user_guid = profileData.guid || '';
    const params = queryParams(query);
    const response = await this.http.get<{ queryParams: string }>(`/api/ebg-query-params${params ? '?' + params : ''}`).toPromise();

    return response.queryParams;
  }

  public async getRedirectUrlDefaultParams(): Promise<string> {
    const response = await this.http.get<{ queryParams: string }>('/api/default-ebg-query-params').toPromise();
    return response.queryParams;
  }

}
