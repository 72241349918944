/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { DataScrollerEvent, DataScrollerService } from 'g3-common-ui';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenEnrollmentService } from '@shared/services/open-enrollment/open-enrollment.service';
import { tap } from 'rxjs/operators';
import { ZonesService } from '@zones/services/zones.service';
import {
  SearchTodayDealsInOfferItemResponse
} from '@shared/services/search/search.service';

export interface NoOffersData {
  image: string;
  title: string;
}

@Injectable()
export class MyOffersService {

  limit = 10;
  offset = 0;
  orderBy = '';
  query = '';
  searchValue = '';

  activeTabName = 'favorites';

  tabs = [
    {
      name: 'dashboard',
      title: 'Dashboard',
      image: '/assets/icons/ic-dashboard.svg',
      noOffers: {
        image: '',
        title: ''
      } as NoOffersData,
      defaultOrderBy: '',
      getOffers: async (options): Promise<void> => this.http
        .get<any>(`${environment.apiUrl}/api/my/offers?isImgRequired=true`)
        .pipe(tap(() => this.dataScrollerService.triggerScroll(DataScrollerEvent.OffersList)))
        .toPromise(),
      viewType: 'dashboard',
      tabIndex: 1010
    },
    {
      name: 'favorites',
      title: 'Favorites',
      image: '/assets/icons/ic-favorites.svg',
      noOffers: {
        image: '/assets/icons/ic-no-saved.svg',
        title: 'Once you begin savings offers, you can review them here.'
      } as NoOffersData,
      defaultOrderBy: 'status',
      getOffers: async (options): Promise<void> => {
        const query = this.getQueryString(null, null, '', false);
        return this.http
          .get<any>(`${environment.apiUrl}/api/my/offers/favorites${query}`)
          .pipe(tap(() => this.dataScrollerService.triggerScroll(DataScrollerEvent.OffersList)))
          .toPromise();
      },
      viewType: 'simple',
      tabIndex: 1020
    },
    {
      name: 'viewed',
      title: 'Viewed',
      image: '/assets/icons/ic-viewed-dark.svg',
      noOffers: {
        image: '/assets/icons/ic-no-viewed.svg',
        title: 'Once you begin viewing offers, you can review them here.'
      } as NoOffersData,
      defaultOrderBy: 'status',
      getOffers: async (isImgRequired: boolean): Promise<void> => {
        const query = this.getQueryString(null, null, '', isImgRequired);
        return this.http
          .get<any>(`${environment.apiUrl}/api/my/offers/viewed${query}`)
          .pipe(tap(() => this.dataScrollerService.triggerScroll(DataScrollerEvent.OffersList)))
          .toPromise();
      },
      viewType: 'simple',
      tabIndex: 1200
    },
  ];

  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private zonesService: ZonesService,
    private dataScrollerService: DataScrollerService,
    private openEnrollmentService: OpenEnrollmentService
  ) { }

  getQueryString(count = null, notGuid = null, orderBy = '', isImgRequired = true): string {

    const chunks = [];
    if (this.query !== '') {
      chunks.push(`q=${this.query}`);
    }

    let query = chunks.join('&');

    query = '?' + query;
    query += count ? `&limit=${count}` : `&limit=${this.limit}`;

    if (this.offset > 0) {
      query += `&offset=${this.offset}`;
    }

    if (orderBy) {
      query += `&orderBy=${orderBy}`;
    } else {
      query += `&orderBy=${this.orderBy}`;
    }

    if (this.searchValue) {
      query += `&q=${this.searchValue}`;
    }

    if (notGuid) {
      query += `&notGuid=${notGuid}`;
    }

    query += `&isImgRequired=${isImgRequired}`;

    return query;
  }

  getQueryParams(): any {
    const result = {};

    if (this.searchValue !== '') {
      result['q'] = this.searchValue;
    }

    result['orderBy'] = this.orderBy;

    return result;
  }

  setOrder(orderBy = ''): void {
    this.orderBy = orderBy;
  }

  setOffset(value: number): any {
    this.offset = value;
  }

  setSearchReviewed(searchValue = ''): void {
    this.searchValue = searchValue;
  }

  async markOfferAsFavorite(guid: string, isSaved = false, isEnrollment = false): Promise<unknown> {
    const action = isSaved ? 'save' : 'unsave';

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('save-offer', {
        action,
        offer: guid,
      }),
      new AnalyticsGAEventModel('save-offer', {
        category: action,
        label: guid
      })
    ]);

    if (isEnrollment) {
      this.openEnrollmentService.updateEnrollmentAd(guid, { is_saved: !!isSaved});
    } else {
      this.zonesService.updateAd(guid, { is_saved: !!isSaved });
    }

    return isSaved
      ? this.http
        .put<any>(`${environment.apiUrl}/api/my/offers/${guid}/favorites`, {})
        .toPromise()

      : this.http
        .delete<any>(`${environment.apiUrl}/api/my/offers/${guid}/favorites`)
        .toPromise();
  }

  getActiveTab(): any {
    return this.tabs.find(t => t.name === this.activeTabName);
  }

  selectTab(tab: string): void {
    this.activeTabName = tab;
  }

  async getRecentlyViewed(count, guid?): Promise<any> {
    const query = this.getQueryString(count, guid, 'viewed');

    return this.http
      .get<any>(`${environment.apiUrl}/api/my/offers/viewed${query}&onlyActive=true&skipCoupons=true`)
      .toPromise();
  }

  async removeUserOffer(offerGuid): Promise<void> {
    const userOfferList = this.activeTabName;

    return this.http
      .delete<any>(`${environment.apiUrl}/api/my/offers/${offerGuid}/${userOfferList}`)
      .toPromise()
      .then(data => {
        this.analyticsService.eventsTrack([
          new AnalyticsInternalEventModel('delete-expired', {
            category: 'delete-expired',
            label: offerGuid
          }),
          new AnalyticsGAEventModel('delete-expired', {
            category: 'delete-expired',
            label: offerGuid
          })
        ]);
      })
      .catch(err => {
        console.error(err);
      });
  }

  public convertOfferToTodayDeal(tddOffer: SearchTodayDealsInOfferItemResponse): any {
    return {
      guid: tddOffer.guid,
      company_name: tddOffer.vendor_brand.title,
      company_brand_tag_logo: tddOffer.vendor_brand.logo_square,
      headline: tddOffer.header_short,
      image_url: tddOffer.images[0]?.url ?? '',
      tag_text: tddOffer.tdd_tag_text,
      views: tddOffer.views || 0,
      code: tddOffer.code,
      destination_url: tddOffer.destination_url,
      is_ebg: tddOffer.is_ebg,
      site_tags: tddOffer.site_tags,
      orig_price: tddOffer.tdd_orig_price,
      curr_price: tddOffer.tdd_curr_price,
      offer_group: tddOffer.tdd_offer_group,
      new_tab: tddOffer.destination_url_new_tab,
      destination_url_type: tddOffer.destination_url_type,
      shopping_category: tddOffer.shopping_category,
      status: tddOffer.status
    };
  }
}
