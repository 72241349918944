import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ProfileFeaturesGuardService {

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) { }

  public async canActivate(): Promise<boolean> {
    const user = await firstValueFrom(this.profileService.profileData$);
    if (user.isProfileFeature) {
      void this.router.navigateByUrl('/');
      return false;
    }

    return true;
  }
}
