/* eslint-disable angularESlint/component-class-suffix */
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { Breakpoints } from '@shared/enums/breakpoints.enum';
import { imageParamsL, imageParamsM, imageParamsXS } from '@widgets/search/constants';
import { SearchWidgetsData } from '@offers/interfaces/search-widgets.interface';
import { ImageUrlPipeOptions } from 'g3-common-ui';

@Component({
  selector: 'app-abstract-widget-search',
  template: '<input type="search" value="Widget Search Abstract" />',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class WidgetSearchAbstract implements OnInit, AfterViewInit {

  public url: string;
  public imageOption: ImageUrlPipeOptions;

  abstract widget: SearchWidgetsData;
  abstract clicked: EventEmitter<SearchWidgetsData>;
  abstract displayed: EventEmitter<SearchWidgetsData>;

  ngOnInit(): void {
    if (this.widget.destination_url) {
      this.url = this.widget.destination_url;
    }

    this.setImageOptions();
  }

  ngAfterViewInit(): void {
    this.displayed.emit(this.widget);
  }

  protected setImageOptions(width: number = window.innerWidth): void {
    if (width >= Breakpoints.Large) {
      this.imageOption = imageParamsL;
    } else if (width >= Breakpoints.Medium) {
      this.imageOption = imageParamsM;
    } else {
      this.imageOption = imageParamsXS;
    }
  }

}
