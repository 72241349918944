<div class="account-confirmation__header"
     [@wrapper]="wrapperState$ | async"
     [ngClass]="{'account-confirmation__header__shrinked': userName && avatarLetter}">
  <div *ngIf="userName && !avatarLetter"
       class="account-confirmation__header__confirm-account">{{userName}}</div>
  <div *ngIf="userName && avatarLetter"
       class="account-confirmation__header__username-container">
    <div class="account-confirmation__header__username">{{userName}}</div>
    <app-avatar *ngIf="avatarLetter"
                [letter]="avatarLetter"
                role="presentation">
    </app-avatar>
  </div>
</div>
@defer {
<div class="account-confirmation__content"
     #scrollToElement
     [@wrapper]="wrapperState$ | async">
  <ng-container [ngSwitch]="step$ | async">
    <app-email-confirmation-step *ngSwitchCase="AccountConfirmationStep.EmailConfirmation">
    </app-email-confirmation-step>

    <app-change-email-confirmation-step *ngSwitchCase="AccountConfirmationStep.EmailChange">
    </app-change-email-confirmation-step>

    <app-paylogix-profile-step *ngSwitchCase="AccountConfirmationStep.PaylogixProfile"></app-paylogix-profile-step>

    <app-profile-prompt-zip-step *ngSwitchCase="AccountConfirmationStep.ProfilePromptZip"></app-profile-prompt-zip-step>

    <app-profile-prompt-phone-step *ngSwitchCase="AccountConfirmationStep.ProfilePromptPhone"></app-profile-prompt-phone-step>

    <app-final-confirmation-step *ngSwitchCase="AccountConfirmationStep.Final"
                                 [promptContent]="mregPromptContent$ | async"></app-final-confirmation-step>
  </ng-container>
  <a *ngIf="!((step$ | async) === AccountConfirmationStep.Final)"
     class="account-confirmation__close-button"
     (click)='onCloseClicked()'>Ask me later</a>
</div>
}
