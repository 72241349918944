import { CustomWidgetDirective } from './custom-widget.directive';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { WidgetsService } from '@app/widgets/services/widgets.service';
import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-custom-widget-host',
  templateUrl: './custom-widget-host.component.html',
  styleUrls: ['./custom-widget-host.component.less']
})
export class CustomWidgetHostComponent implements OnChanges, AfterViewInit {

  @Input() widget: CustomWidgetModel;
  @Input() groupTabIndex: number;
  @Input() isEagerLoading = false;

  @ViewChild(CustomWidgetDirective) widgetHost: CustomWidgetDirective;

  constructor(
    private widgetsService: WidgetsService
  ) { }

  ngOnChanges(): void {
    this.widgetsService.render(this.widget, this.widgetHost, this.groupTabIndex, this.isEagerLoading);
  }

  ngAfterViewInit(): void {
    this.widgetsService.render(this.widget, this.widgetHost, this.groupTabIndex, this.isEagerLoading);
  }

}
