import { Injectable } from '@angular/core';

@Injectable()
export class ImageHandlerService {
  private defaultPlaceholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  public swapImage(img: HTMLImageElement): void {
    img.src = this.defaultPlaceholder;
  }

  public get getDefaultPlaceholder(): string {
    return this.defaultPlaceholder;
  }
}
