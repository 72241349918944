import { Inject, Injectable, OnInit } from '@angular/core';
import { WINDOW, EncryptionService } from 'g3-common-ui';
import {
  DLK_LOADING_MODE,
  DLK_MESSAGE_PREFIX,
  POPULATE_PARAMS_KEY,
  SILENT_DLK_LOGIN,
  UTM_PARAMS
} from './dlk-access.constants';
import { AuthService } from '../auth.service';
import { environment } from '@environments/environment';
import { queryParams } from '@shared/helpers/query-string';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { AutoLoginCompleteTypes, DlkAccessTypes, KnownLoginStatusTypes } from './dlk-access.enum';
import { Params } from '@angular/router';
import { RetroactiveUpdateService } from '@app/shared/services/retroactive-update/retroactive-update.service';
import { KnownUser, KnownUserLoginData, PopulateEmailParams } from '../../interfaces/dlk-access.interface';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { KnownUserData } from '@app/shared/interfaces/known-user.interface';

@Injectable({
  providedIn: 'root'
})
export class DLKAccessService {
  public DLKValue: string;
  public populateParams: PopulateEmailParams = {} as PopulateEmailParams;
  public knownLoginStatusStream: Subject<KnownLoginStatusTypes> = new Subject<KnownLoginStatusTypes>();
  public readonly knownLoginStatus$ = this.knownLoginStatusStream.asObservable();

  private isListenerAttached = false;

  public constructor(
    @Inject(WINDOW) private window: WINDOW,
    private readonly authService: AuthService,
    private readonly knownUserService: KnownUserService,
    private readonly retroactiveUpdateService: RetroactiveUpdateService,
    private readonly http: HttpClient,
    private readonly encryptionService: EncryptionService,
  ) {}

  public getPopulateParams(): PopulateEmailParams {
    const populateParams = sessionStorage.getItem(POPULATE_PARAMS_KEY);

    if (populateParams) {
      this.populateParams = JSON.parse(populateParams);
      sessionStorage.removeItem(POPULATE_PARAMS_KEY);
    }

    return this.populateParams;
  }

  public async silentDLKLogin(DLKValue: string, isSystemAuthorizeEnabled?: boolean): Promise<void> {
    this.DLKValue = DLKValue;

    this.knownLoginStatusStream.next(KnownLoginStatusTypes.inProgress);

    if (isSystemAuthorizeEnabled) {
      await this.performSystemKnownAuthorize();
      this.knownLoginStatusStream.next(KnownLoginStatusTypes.success);
      return;
    }

    await this.useSilentFlow();
    this.knownLoginStatusStream.next(KnownLoginStatusTypes.success);
  }

  public async useSilentFlow(): Promise<void> {
    if (!this.window?.document) {
      throw new Error('silent DLK is not supported on this platform');
    }

    this.removeExistingIFrame();

    const url = this.getAuthorizeUrl({
      loadingMode: DLK_LOADING_MODE,
      redirectUri: `${window.location.origin}/auto-login?iframe=true`
    });

    const iframe = document.createElement('iframe');
    iframe.id = SILENT_DLK_LOGIN;
    iframe.setAttribute('src', url);
    iframe.style['display'] = 'none';

    await this.setupSilentDLKEventListener(iframe);
  }

  public getAuthorizeUrl(options: { loadingMode: string; redirectUri: string }): string {
    const subdomain = this.authService.getSubdomain();
    const queryObject = Object.assign(
      {
        subdomain,
        response_type: 'code',
        client_id: environment.oauth.clientId
      },
      this.getUtmParamsFromRedirect(options.redirectUri)
    );

    queryObject['loading_mode'] = options.loadingMode;
    queryObject['redirect_uri'] = options.redirectUri;

    queryObject['DLK'] = this.DLKValue;

    const url = `${this.authService.getAuthDomain()}/auth/authorize?${queryParams(queryObject)}`;

    return url;
  }

  public getDLKParamKey(queryParams: Params): string {
    const upperCaseParam = 'DLK';
    const lowerCaseParam = 'dlk';
    let dlkKey: string;

    if (queryParams[upperCaseParam]) {
      dlkKey = upperCaseParam;
    }
    if (queryParams[lowerCaseParam]) {
      dlkKey = lowerCaseParam;
    }

    return dlkKey;
  }

  public redirectToSilentDLK(params: Params): void {
    this.window.location.href = `${this.window.location.origin}/embedded/silent-dlk.html?${queryParams(params)}`;
  }

  private removeSilentDLKEventListener(): void {
    if (this.isListenerAttached) {
      this.window.removeEventListener('message', this.silentDLKListener);
    }

    this.isListenerAttached = false;
  }

  private async setupSilentDLKEventListener(iframe: HTMLElement): Promise<void> {
    this.removeSilentDLKEventListener();

    document.body.appendChild(iframe);

    return new Promise(resolve => {
      this.window?.addEventListener('message', async (event: MessageEvent) => {
        this.isListenerAttached = true;

        const access = await this.silentDLKListener(event);
        if (access === DlkAccessTypes.granted) {
          resolve();
        }
      });
    });
  }

  private async silentDLKListener(event: MessageEvent): Promise<DlkAccessTypes> {
    if (!event?.data || typeof event.data !== 'string') {
      return;
    }

    const prefixedMessage: string = event.data;
    if (!prefixedMessage.startsWith(DLK_MESSAGE_PREFIX)) {
      return DlkAccessTypes.blocked;
    }

    const message = prefixedMessage.replace(DLK_MESSAGE_PREFIX, '');
    await this.handleDLKLogin(message);
    return DlkAccessTypes.granted;
  }

  private async handleDLKLogin(message: string): Promise<void> {
    if (message.includes(AutoLoginCompleteTypes.failed)) {
      if (this.window.location.href.includes('/email-landing')) {
        console.error('Email Landing: DLK auto-login failed');
        return;
      }

      this.knownUserService.clearAll();
      this.authService.redirectToAuthorize();
      return;
    }

    if (message.includes(AutoLoginCompleteTypes.imported)) {
      const email = decodeURIComponent(this.getMessageOption(message, 'email'));
      const populationType = this.getMessageOption(message, 'population_type');
      this.populateParams = { email, population_type: populationType };
      sessionStorage.setItem(POPULATE_PARAMS_KEY, JSON.stringify(this.populateParams));

      if (this.window.location.href.includes('/email-landing')) {
        console.error('Email Landing: DLK auto-login not supported for imported user');
        return;
      }

      if (!email || !populationType) {
        this.authService.redirectToAuthorize();
        return;
      }

      this.authService.redirectToAuthorize('/', false, this.populateParams);
      return;
    }

    if (message.includes(AutoLoginCompleteTypes.retroactive)) {
      if (this.window.location.href.includes('/email-landing')) {
        console.error('Email Landing: DLK auto-login cannot be completed for retroactive');
        return;
      }

      const isRetroactiveEmailUpdate = this.retroactiveUpdateService.getIsRetroactiveEmailUpdate();
      this.knownUserService.clearAll();
      this.authService.redirectToAuthorize(null, isRetroactiveEmailUpdate);
      return;
    }

    if (message.includes(AutoLoginCompleteTypes.completeRegistration)) {
      if (this.window.location.href.includes('/email-landing')) {
        console.error('Email Landing: DLK auto-login cannot be completed because registration is not finished');
        return;
      }

      this.authService.redirectToAuthorize();
    }
  }

  private getUtmParamsFromRedirect(redirectUri: string): { [key: string]: string } {
    if (!redirectUri) {
      return {};
    }

    try {
      const redirect = new URL(redirectUri);
      const redirectQueryObject = {};

      for (const param of UTM_PARAMS) {
        if (redirect.searchParams.has(param)) {
          redirectQueryObject[param] = redirect.searchParams.get(param);
        }
      }

      return redirectQueryObject;
    } catch (e) {
      return {};
    }
  }

  private removeExistingIFrame(): void {
    const existingIFrame = document.getElementById(SILENT_DLK_LOGIN);

    if (existingIFrame) {
      document.body.removeChild(existingIFrame);
    }
  }

  private getMessageOption(message: string, option: string): string {
    const regex = new RegExp(`${option}=(.*?)(?:&|$)`);
    const match = message.match(regex);

    return match ? match[1] : null;
  }

  private async performSystemKnownAuthorize(): Promise<void> {
    const options = {
      loadingMode: DLK_LOADING_MODE,
      redirectUri: `${window.location.origin}/auto-login`
    };
    const subdomain = this.authService.getSubdomain();
    const body = Object.assign(
      {
        subdomain,
        response_type: 'code',
        client_id: environment.oauth.clientId
      },
      this.getUtmParamsFromRedirect(options.redirectUri)
    );

    body['dlk'] = this.DLKValue;

    try {

      const knownUserLoginData = await lastValueFrom(
        this.http.post<KnownUserLoginData>(`${environment.apiUrl}/auth/system/authorize`, body)
      );

      if (knownUserLoginData.populationData) {
        this.knownUserService.clearAll();
        const { cipher } = await this.encryptionService.encrypt(this.window.location.origin + '/', knownUserLoginData?.populationData?.email);
        this.populateParams = {
          email: cipher,
          population_type: knownUserLoginData?.populationData?.populationType
        };

        this.authService.redirectToAuthorize(null, false, this.populateParams);
        return;
      }

      if (knownUserLoginData.shouldUpdateEmail) {
        this.knownUserService.clearAll();
        this.authService.redirectToAuthorize(null, true);
        return;
      }

      if (knownUserLoginData.shouldLogin) {
        this.knownUserService.clearAll();
        this.authService.redirectToAuthorize();
        return;
      }

      const knownData = this.getKnownData(knownUserLoginData.known_user);
      this.knownUserService.clearAll();
      this.knownUserService.setAllKnownData(knownData);

      const tokenData = {
        access_token: knownUserLoginData.access_token,
        expires_in: knownUserLoginData.expires_in,
        short_token: knownUserLoginData.short_token,
        token_type: knownUserLoginData.token_type,
        scope: knownUserLoginData.scope
      };
      this.authService.setAuthorizeData(tokenData);

    } catch (error) {
      // ? If for some reason we cannot complete system login, we will fallback to silent dlk
      // TODO: Move all dlk processing from auth-ui to the backend (imported user)
      await this.useSilentFlow();
    }
  }

  private getKnownData(user: Partial<KnownUser>): KnownUserData {
    return {
      email: user.email,
      contact: user.email_contact,
      type: user.known_user_type,
      guid: user.known_user_guid,
      childGuid: user.known_child_guid,
      childDLK: user.known_child_dlk
    };
  }
}
