/* eslint-disable typescriptESlintPlugin/no-misused-promises */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Inject
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  AutoTabIndexService,
  FADE_IN_OUT_NOTIFICATION_ANIMATION,
  Notification,
  NotificationDismissTypes,
  PlatformService,
  WINDOW,
} from 'g3-common-ui';
import { cdnHostTypes, ConfigService, ExperimentItem } from '@app/shared/services/config.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { ContactFormService } from '@shared/services/contact-form.service';
import { CustomLinkResponse } from '@shared/services/config.service';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { InfiniteScrollService } from '@app/shared/services/infinite-scroll.service';
import { ModalTodayDealService } from '@app/shared/components/modal-pass-thru/modal-today-deal.service';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { Observable, Subject, Subscription, from } from 'rxjs';
import { OffersListAdminLayoutService } from '@app/shared/services/offers-list-admin-layout/offers-list-admin-layout.service';
import { OpenEnrollmentService } from '@shared/services/open-enrollment/open-enrollment.service';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { ZoneSection } from '@zones/interfaces/zones-search-response.interface';
import { ZonesService } from '@zones/services/zones.service';
import { AuthService } from '@core/auth/services/auth.service';
import { NotiBlockService } from '@zones/services/noti-block.service';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in-layout.component.html',
  styleUrls: ['./logged-in-layout.component.less'],
  animations: [
    FADE_IN_OUT_NOTIFICATION_ANIMATION
  ],
  providers: [AutoTabIndexService]
})
export class LoggedInLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  public isHomepage = true;

  public notification: Notification = {} as Notification;
  public wideContainerClassName = 'wide-container';
  public subscriptions: Subscription[] = [];
  public customLinksMyProfile = [] as CustomLinkResponse[];
  public customLinksMyOffers = [] as CustomLinkResponse[];
  public listingMapRoute = '/listing';
  // We need to hide these components to have full screen map
  public isFullScreenMap = false;
  public hideFooter = false;
  public isOfferDetailsPage = false;
  public isShowNavigationZoneSection = false;
  public topZoneSection: ZoneSection;
  public siteTag: string;
  public mainSectionTopMargin: string;
  public isTabletOrMore: boolean;
  public MOBILE_VIEWPORT_WIDTH = 767;
  public isNotiBlockOpen: boolean;

  private systemNotificationsEnabled: boolean;
  private countryLast: string;
  private destroyStream = new Subject<void>();

  constructor(
    private contactFormService: ContactFormService,
    private analyticsService: AnalyticsService,
    private notificationService: NotificationsService,
    private router: Router,
    private configService: ConfigService,
    private profileService: ProfileService,
    private platformService: PlatformService,
    private modalTodayDealService: ModalTodayDealService,
    private clickOutService: ClickOutService,
    private zonesService: ZonesService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private infiniteScrollService: InfiniteScrollService,
    private offersListAdminLayoutService: OffersListAdminLayoutService,
    private openEnrollmentService: OpenEnrollmentService,
    private authService: AuthService,
    private notiBlockService: NotiBlockService,
    @Inject(WINDOW) private window: WINDOW,
  ) { }

  public get infiniteScrollState$(): Observable<boolean> {
    return this.infiniteScrollService.getInfiniteScrollState();
  }

  public get canShowFooter(): boolean {
    return !this.hideFooter && !!this.country;
  }

  public get translateLanguageCodes(): string[] {
    return this.configService.getOption<string[]>('translate_language_codes', []);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(e: any): void {
    this.isTabletOrMore = e.target.innerWidth > this.MOBILE_VIEWPORT_WIDTH;
    this.handleMainSectionTopMargin();
  }

  public async ngOnInit(): Promise<void> {
    if (!this.authService.hasAccessToken()) {
      return;
    }
    this.siteTag = this.platformService.currentPlatform.SITE_TAG;

    this.listingRouteHeaderFooterSetup(this.router.url);
    this.subscribeOnRoute();
    this.subscribeOnNotibannerState();

    this.profileService.profileData$.pipe(
      filter(data => Object.keys(data).length > 0),
      take(1)
    ).subscribe(profileData => {
      this.countryLast = profileData.countryLast || 'US';

      this.subscribeOnZones();

      this.offersListAdminLayoutService.takeoverState
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.destroyStream)
        )
        .subscribe(state => {
          this.isHomepage = !state;
          void this.openEnrollmentService.fetchEnrollment(state, this.countryLast);
        });
    });

    if (this.route.snapshot.queryParams['contactUs']) {
      void this.showContactUs('');
    }

    this.systemNotificationsEnabled = this.configService.hasFeature('ad_model_notifications');
    this.customLinksMyProfile = this.configService.getCustomLinks('My Account');
    this.customLinksMyOffers = this.configService.getCustomLinks('My Offers');
    this.isTabletOrMore = window.innerWidth > this.MOBILE_VIEWPORT_WIDTH;
    this.handleMainSectionTopMargin();

    await this.loadNotification();
    this.modalTodayDealService.open();
    this.zoneNavigationRouteSetup(this.router.url);

    const localRedirect = localStorage.getItem('local-redirect-url');
    if (localRedirect) {
      const redirectInfo = JSON.parse(localRedirect);
      await this.router.navigate([redirectInfo.url], { queryParams: redirectInfo.queryParams });
      localStorage.removeItem('local-redirect-url');
      this.window.location.reload();
    }
  }

  public ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();

    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public get country(): string {
    return this.countryLast;
  }

  public get hostName(): string {
    return window.location.origin;
  }

  public get apiHost(): string {
    return this.configService.getCdn(cdnHostTypes.apiHost);
  }

  public get cdn(): string {
    return this.configService.getCdn(cdnHostTypes.staticImagesHost) || 'false';
  }

  public get wideContainerClass(): string {
    return 'wide-container';
  }

  public async showContactUs(selected: string): Promise<void> {
    await this.contactFormService.openContactFormModal({
      reason: selected
    });
  }

  public async loadNotification(guids: number[] | string[] = null): Promise<void> {
    try {
      if (this.systemNotificationsEnabled) {
        await this.loadSystemWideNotification(guids as string[]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async onNotificationClose(notification: Notification): Promise<any> {
    this.configService.updateNotificationBannerState(false);
    this.trackAnalyticsEvents('notification-close', {
      guid: notification.guid,
    });

    const viewedNotifications = this.systemNotificationsEnabled ? [notification.guid] as string[] :
      await this.notificationService.addToViewedNotifications(notification, 'dismissed', 'ui');

    await this.loadNotification(viewedNotifications);
  }

  public async onEnrollClick(notification: Notification): Promise<void> {
    await this.trackAnalyticsEvents('notification-click', {
      guid: notification.guid,
    });

    if (notification.isExternalSource) {
      await this.clickOutService.clickOut(notification.url, '', {}, true);
    } else {
      void this.router.navigateByUrl(notification.url);
    }

    const viewedNotifications = await this.notificationService.addToViewedNotifications(notification, 'used', 'ui');
    await this.loadNotification(viewedNotifications);
  }

  public trackAnalyticsEvents(action: any, data: any): void {
    try {
      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel(action, {
          notification: data
        }),
        new AnalyticsGAEventModel(action, {
          category: action,
          label: data.guid,
        })
      ]);
    } catch {
      console.error('error posting analytics event');
    }
  }

  public subscribeOnRoute(): void {
    this.subscriptions.push(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.listingRouteHeaderFooterSetup(event.url);
          this.zoneNavigationRouteSetup(event.url);
          this.handleMainSectionTopMargin(event.url);
        })
    );
  }

  public listingRouteHeaderFooterSetup(url: string): void {
    if (url.includes('listing')) {
      this.isFullScreenMap = true;
      this.hideFooter = true;
    } else {
      this.isFullScreenMap = false;
      this.hideFooter = false;
    }
  }

  public zoneNavigationRouteSetup(url: string): void {
    this.isOfferDetailsPage = url.includes('/offers/');
    // do not show on full map page if system notifications is enabled
    this.isShowNavigationZoneSection = !(this.systemNotificationsEnabled && this.isFullScreenMap);
  }

  public subscribeOnZones(): void {
    from(this.zonesService.getZones('navigation', this.countryLast, ['2'])).pipe(
      takeUntil(this.destroyStream),
    ).subscribe(data => {
      this.topZoneSection = data.items[0];
    });
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public subscribeOnNotibannerState(): void {
    this.subscriptions.push(this.notiBlockService.notiBannerState$.subscribe(isOpen => {
      this.isNotiBlockOpen = isOpen;
      this.handleMainSectionTopMargin();
    }));
  }

  public handleMainSectionTopMargin(url?: string): void {
    const isHomepage = url === '/';
    if (this.isNotiBlockOpen && !isHomepage) {
      this.mainSectionTopMargin = this.isTabletOrMore ? '32px' : '24px';
    } else {
      this.mainSectionTopMargin = '0';
    }
  }

  private async loadSystemWideNotification(guids: string[] = null): Promise<any> {
    try {
      const systemWideNotifications = (await this.notificationService.getSystemWideNotifications(guids).toPromise()) || [];
      if (!systemWideNotifications.length) {
        this.notification = {} as Notification;
        this.configService.updateNotificationBannerState(false);
        return;
      }

      // todo: this will be enhanced in future in a case if we need to display multiple items
      const notification = systemWideNotifications[0];

      this.notification = {
        message: notification.message,
        guid: notification.guid,
        type: 'alert',
        dismissType: NotificationDismissTypes.Dismissible
      } as any;
      this.configService.updateNotificationBannerState(true);
    } catch (e) {
      console.error(e);
    }
  }

}
