import { cdnHostTypes, ConfigService } from './config.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'g3-common-ui';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeStylesService {

  public themeStyles: string;
  private themeColors = '';
  private themePropertiesScope = ':root,app-logged-in,ngb-modal-window';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    @Inject(WINDOW) private window: WINDOW
  ) { }

  public async getStyles(): Promise<string> {
    try {
      const subdomain = this.configService.getOption<string>('subdomain', this.window.location.host.split('.')[0]);
      const url = this.configService.getCdn(cdnHostTypes.apiHost) || environment.apiUrl;

      const theme = await firstValueFrom(this.http.get(`${url}/api/${subdomain}/marketplace-styles.css`, { responseType: 'text' }));
      const themeProperties = this.configService.getOption('color_theme', {});

      this.themeStyles = theme;
      this.themeColors = this.convertCssPropertiesToStyle(themeProperties, this.themePropertiesScope) ?? '';

      return this.themeStyles;

    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public setCustomStyles(): void {
    const customStyle = this.themeStyles;
    const colorTheme = this.themeColors;
    const document = this.window.document;

    const head = document.head || document.getElementsByTagName('head')[0];
    const styleTag = document.createElement('style');

    styleTag.appendChild(document.createTextNode(customStyle));
    const styleElements = head.getElementsByTagName('style');

    // place marketplace styles before other styles
    if (styleElements.length > 1) {
      head.insertBefore(styleTag, styleElements[0]);
    } else {
      head.appendChild(styleTag);
    }

    const themeStyleTag = document.createElement('style');
    themeStyleTag.appendChild(document.createTextNode(colorTheme));
    head.appendChild(themeStyleTag);
  }

  private convertCssPropertiesToStyle(properties: Record<string, string>, scopeSelector: string): string {
    const variables = Object
      .entries(properties)
      .map(([key, value]) => `${key}: ${value};`)
      .join('\n');
    return `${scopeSelector} {\n${variables}\n}`;
  }
}
