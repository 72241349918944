import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CtaOptions } from '@app/zones/services/cta-element.service';
import { ImageUrlPipe } from 'g3-common-ui';
import { ImageHandlerService } from '@shared/services/image-handler/image-handler.service';

@Component({
  selector: 'app-cta-product',
  templateUrl: './cta-product.component.html',
  styleUrls: ['./cta-product.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CtaProductComponent implements OnInit {

  @Input() ctaProduct: CtaOptions;
  @Input() groupTabIndex: number;
  @Input() wideContainerClass = '';
  @Input() anchorLink = '';
  @Input() isEagerLoading: false;

  @Output() handleCta = new EventEmitter<void>();

  public imageLoading = 'lazy';

  constructor(
    public imageHandlerService: ImageHandlerService,
    private imageUrlPipe: ImageUrlPipe
  ) { }

  public ngOnInit(): void {
    this.imageLoading = this.isEagerLoading ? 'eager' : 'lazy';
  }

  public get foregroundUrl(): string {
    const imageUrl = this.ctaProduct && this.ctaProduct.image_url ? this.ctaProduct.image_url : '';
    return this.imageUrlPipe.transform(imageUrl, { w: 508, h: 496 }, false) as string;
  }

  public handleCtaClick(): void {
    this.handleCta.emit();
  }
}
