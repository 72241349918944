import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  Input,
  Injectable,
} from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';
import { ConfigService } from '../services/config.service';

@Injectable({ providedIn: 'root' })
@Directive({
  selector: '[appGooglePlaces]',
  exportAs: 'ngx-google-places'
})
export class NgxGooglePlacesDirective implements OnInit {
  @Input() public options: google.maps.places.AutocompleteOptions;
  @Input() public deferLoading = false;

  @Output() public addressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();

  public element: HTMLInputElement;
  public autocomplete: google.maps.places.Autocomplete;
  public eventListener: google.maps.MapsEventListener;
  public place: google.maps.places.PlaceResult;

  private scriptLoadingInProgress: boolean;
  private scriptInjected: boolean;

  public constructor(
    elRef: ElementRef,
    private readonly configService: ConfigService,
    private ngZone: NgZone
  ) {
    this.element = elRef.nativeElement;
  }

  public get isInitedOrInProgress(): boolean {
    return this.scriptInjected || this.scriptLoadingInProgress;
  }

  public async ngOnInit(): Promise<void> {
    if (this.deferLoading) {
      void this.deferGoogleAutocompleteLoading();
    } else {
      await this.initGoogleAutocomplete();
    }
  }

  public async initGoogleAutocomplete(): Promise<void> {
    await this.loadGoogleMaps();

    this.scriptLoadingInProgress = true;
    this.autocomplete = new google.maps.places.Autocomplete(this.element);

    // ? Monitor place changes in the input
    if (this.autocomplete.addListener !== null) {
      this.eventListener = google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.handleChangeEvent());
    }

    this.scriptLoadingInProgress = false;
  }

  public async loadGoogleMaps(): Promise<void> {
    if (this.scriptInjected) {
      return;
    }

    try {
      await this.injectGoogleMapsScript();
      this.scriptInjected = true;
    } catch (error) {
      console.error(error);
    }
  }

  public reset(): void {
    this.autocomplete?.setComponentRestrictions(this.options?.componentRestrictions || null);
    this.autocomplete?.setTypes(this.options?.types || []);
  }

  public handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete?.getPlace();

      if (this.place) {
        this.addressChange.emit(this.place);
      }
    });
  }

  private async deferGoogleAutocompleteLoading(): Promise<void> {
    const deferTimeout = this.configService.controls.google_maps_defer_timeout;
    const timeLeft = (deferTimeout * 1000) - performance.now();

    if (timeLeft > 0) {
      await firstValueFrom(timer(timeLeft));
    }

    await this.initGoogleAutocomplete();
  }

  private async injectGoogleMapsScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const src = 'https://maps.googleapis.com/maps/api/js?client=gme-entertainmentbenefits&libraries=places';
      const instance = document.querySelector('#googleapis-maps');

      if (instance) {
        if (window?.google?.maps?.places?.Autocomplete) {
          resolve();
        } else {
          instance.addEventListener('load', (): void => resolve());
        }
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.id = 'googleapis-maps';
        script.onload = (): void => resolve();
        script.onerror = (): void => reject();
        document.body.appendChild(script);
      }
    });
  }

}
