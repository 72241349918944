import { Router } from '@angular/router';
import { ConfigService } from '@app/shared/services/config.service';
import { Injectable } from '@angular/core';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SubscriptionsGuardService {

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private configService: ConfigService
  ) { }

  public async canActivate(): Promise<boolean> {
    const user = await firstValueFrom(this.profileService.profileData$);
    const countryLast = user && user.countryLast ? user.countryLast : '';

    if (this.configService.isSubscriptionCenterEnabled(countryLast)) {
      return true;
    }

    void this.router.navigateByUrl('/');
    return false;
  }
}
