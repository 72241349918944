/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { clearLocalStorage, UrlHelperService } from 'g3-common-ui';
import { ConfigService } from '@shared/services/config.service';
import { delay, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { OAuthErrorEvent, OAuthEvent, OAuthInfoEvent, OAuthSuccessEvent } from './auth-events';
import { interval, Observable, of, race, Subject, Subscription } from 'rxjs';
import { PermissionService } from '@core/auth/services/permission.service';
import { queryParams } from '@shared/helpers/query-string';
import { Params, Router, RoutesRecognized } from '@angular/router';
import { CorporateRedirectService } from '@core/auth/services/corporate-redirect.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { KnownUserInfoResponse } from '../interfaces/auth.interface';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { ID_CLOSED } from '@app/shared/constants/auth.constants';

export const WA_HOST_NAME = 'workingadvantage';
export const BP_HOST_NAME = 'beneplace';

const getFullyQualifiedUri = (redirectUri: string): string => {
  if (!redirectUri) {
    return window.location.href;
  }

  if (redirectUri.match(/^https?:\/\//)) {
    return redirectUri;
  }

  if (redirectUri.startsWith('/')) {
    return window.location.origin + redirectUri;
  }

  return `${window.location.origin}/${redirectUri}`;
};

interface ProfileResponse {
  id: string;
  guid: string;
  email: string;
  first_name: string;
  last_name: string;
  date_format: string;
  member_date: string;
  password_changed_at: string;
  last_sign_in_at: string;
  country_last: string;
  change_email: {
    email: string;
    is_expired: boolean;
  };
  is_allow_sign_on_new_account: boolean;
  external_authentication?: string;
}

interface AuthPass {
  login: string;
  password: string;
  rememberMe?: boolean;
  countryCode?: string;
  isConfirmationStep?: boolean;
}

interface KnownRedirectParams {
  known_email: string;
  known_email_contact: string;
  known_user_type: string;
  known_user: boolean;
  known_user_guid: string;
  known_child_guid?: string;
  known_child_dlk?: string;
}

/**
 * Additional options that can be passt to tryLogin.
 */
class LoginOptions {
  /**
   * Is called, after a token has been received and
   * successfully validated.
   *
   * Deprecated:  Use property ``events`` on AuthService instead.
   */
  onTokenReceived?: (receivedTokens: ReceivedTokens) => void;

  /**
   * Hook, to validate the received tokens.
   *
   * Deprecated:  Use property ``tokenValidationHandler`` on AuthService instead.
   */
  validationHandler?: (receivedTokens: ReceivedTokens) => Promise<any>;

  /**
   * Called when tryLogin detects that the auth server
   * included an error message into the hash fragment.
   *
   * Deprecated:  Use property ``events`` on AuthService instead.
   */
  onLoginError?: (params: object) => void;

  /**
   * A custom hash fragment to be used instead of the
   * actual one. This is used for silent refreshes, to
   * pass the iframes hash fragment to this method.
   */
  customHashFragment?: string;

  /**
   * Set this to true to disable the oauth2 state
   * check which is a best practice to avoid
   * security attacks.
   * As OIDC defines a nonce check that includes
   * this, this can be set to true when only doing
   * OIDC.
   */
  disableOAuth2StateCheck?: boolean;

  /**
   * Normally, you want to clear your hash fragment after
   * the lib read the token(s) so that they are not displayed
   * anymore in the url. If not, set this to true.
   */
  preventClearHashAfterLogin? = false;
}

/**
 * Represents the received tokens, the received state
 * and the parsed claims from the id-token.
 */
class ReceivedTokens {
  idToken?: string;
  accessToken?: string;
  idClaims?: object;
  state?: string;
}

export enum CorporateRedirectToken {
  PREFIX_TOKEN = 'corpRedirectUri=[',
  SUFFIX_TOKEN = ']corp'
}

@Injectable()
export class AuthService {
  // url for navigation after logged in
  hasHarvestedTokens = false;
  harvestingTokenFromHashFragment = false;
  silentRefreshIFrameName = 'silent-refresh';
  silentRefreshShowIFrame = false;

  public events: Observable<OAuthEvent>;
  protected eventsSubject: Subject<OAuthEvent> = new Subject<OAuthEvent>();
  protected silentRefreshPostMessageEventListener: EventListener;
  protected accessTokenTimeoutSubscription: Subscription;
  protected sessionCheckTimer: any;
  protected silentRefreshSubject: string;
  protected silentRefreshMessagePrefix: string;
  protected silentRefreshTimeout = 5000;
  protected timeoutFactor = 0.65;
  protected fallbackAccessTokenExpirationTimeInSec = 60 * 10;

  private corporateRedirectUri = '';

  private readonly UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'ebg_source'];

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    public permissionService: PermissionService,
    private router: Router,
    private location: Location,
    private corporateRedirectService: CorporateRedirectService,
    protected urlHelper: UrlHelperService,
    protected ngZone: NgZone,
    private knownUserService: KnownUserService,
    private readonly adobeAnalyticsEventsService: AdobeAnalyticsEventsService
  ) {
    this.events = this.eventsSubject.asObservable();
    this.router.events.pipe(filter(e => e instanceof RoutesRecognized)).subscribe((data: RoutesRecognized) => {
      this.corporateRedirectUri = '';
      const params = data.state.root.queryParams;

      if (this.corporateRedirectService.isAllowedCorporateRedirect(params)) {
        this.corporateRedirectUri = params['corpRedirectUri'];

        return;
      }
    });
  }

  get isWorkingAdvantage(): boolean {
    return window.location.origin.includes('workingadvantage.com');
  }

  public async tryLogin(options?: LoginOptions, code?: string): Promise<void> {
    options = options || {};

    // Workaround to support sometimes auth-guard, sometimes no auth-guard
    // Must be able to run with setTimeout() from ctor if no auth-guard
    // Must also be able to run before auth-guard denies access and redirects (i.e., from this.isAuthenticated())
    if (this.harvestingTokenFromHashFragment) {
      return;
    }

    const hashFragment = options.customHashFragment
      ? this.urlHelper.getHashFragmentParams(options.customHashFragment)
      : this.urlHelper.getHashFragmentParams();

    // Callback: swap authorization code for tokens
    const authorizationCode = code || hashFragment['code'];
    const grantedScopes = hashFragment['scope'];
    const userGuid = hashFragment['user_guid'];

    if (authorizationCode) {
      this.harvestingTokenFromHashFragment = true;
      let url = `${environment.apiUrl}/auth/token?grant_type=authorization_code&client_id=${environment.oauth.clientId}&code=${authorizationCode}`;

      if (userGuid) {
        url += `&user_guid=${userGuid}`;
      }

      try {
        const responseData = await this.http.get<any>(url).toPromise();

        localStorage.setItem('access_token', responseData.access_token);
        localStorage.setItem('short_token', responseData.short_token);

        if (responseData.scope) {
          this.permissionService.define(responseData.scope.split(' '));
          this.clearKnownUserRelatedData(responseData.scope.split(' '));
        }

        this.setAuthenticated(responseData.expires_in);
        this.hasHarvestedTokens = true;
        this.clearHashFragment(options);

        if (hashFragment['known_user_guid']) {
          const proceedWithKnownLogin =
            this.configService.getOption('is_allow_login_without_password', false) ||
            !hashFragment['known_user_type']?.includes('not_set');
          // * Block known_not_set_* from home view on V2
          if (!proceedWithKnownLogin) {
            this.setUnauthenticated();
            await this.signOut();
            this.redirectToSignInDirectly();
            return;
          }
        }

        if (hashFragment['known_user_guid'] || hashFragment['known_child_guid']) {
          this.knownLoginRedirect(hashFragment);
        }

        if (hashFragment['token_refreshed']) {
          this.adobeAnalyticsEventsService.emitSignInSuccessEvent('remember_me');
        }
      } catch (err) {
        console.error(err);
        this.adobeAnalyticsEventsService.emitSignInFailureEvent('remember_me', err.error?.name || 'remember_me', true);
        return;
      }
    } else if (grantedScopes) {
      this.harvestingTokenFromHashFragment = true;

      //* grantedScopes contains response from auth/authorize after token refresh
      if (hashFragment['token_refreshed']) {
        this.adobeAnalyticsEventsService.emitSignInSuccessEvent('remember_me');
      }

      try {
        this.setAuthenticated(hashFragment['expires_in'] || this.fallbackAccessTokenExpirationTimeInSec);
        localStorage.removeItem('access_token');
        localStorage.removeItem('short_token');
        const scopes = atob(grantedScopes).split(',');
        if (scopes) {
          this.permissionService.define(scopes);
          this.clearKnownUserRelatedData(scopes);
        }

        // Don't set this again except if SPA reload (from redirect from auth)
        this.hasHarvestedTokens = true;
        this.clearHashFragment(options);
      } catch (err) {
        this.adobeAnalyticsEventsService.emitSignInFailureEvent('remember_me', err.error?.name || 'remember_me');

        void this.router.navigateByUrl('/');
      }
    }
  }

  public knownLoginRedirect(hashFragment = {}): void {
    const allKnownData = {
      childGuid: hashFragment['known_child_guid'],
      childDLK: hashFragment['known_child_dlk'],
      email: hashFragment['known_user_email'],
      contact: hashFragment['known_user_email_contact'],
      type: hashFragment['known_user_type'],
      guid: hashFragment['known_user_guid'],
    };

    this.knownUserService.setAllKnownData(allKnownData);
  }

  public getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  public hasAccessToken(): boolean {
    return Boolean(this.getAccessToken());
  }

  public getShortToken(): string {
    return localStorage.getItem('short_token');
  }

  public getSubdomain(): string {
    return this.configService.getOption<string>('subdomain', '');
  }

  public getPrevSubdomain(): string {
    return this.configService.getOption<string>('prev_subdomain', '');
  }

  public getMovedToSubdomain(): string {
    return this.configService.getOption<string>('moved_to', '');
  }

  public getAuthDomain(): string {
    const domainOverride = this.configService.getOption<string>('primary_top_level_domain', '');
    const oauthUrlOverride = domainOverride ? window.location.protocol + '//auth.' + domainOverride : false;

    const oauthUrl = oauthUrlOverride ? oauthUrlOverride : this.getEnvironmentOAuthUrl();

    return oauthUrl;
  }

  public redirectToNewSubdomain(isAuthenticated: boolean, newSubdomain: string): void {
    this.setUnauthenticated();
    const currentHost = window.location.host.split('.')[0];

    if (isAuthenticated) {
      const newURL = this.configService.replacePreviousHost(window.location.href, currentHost, newSubdomain);
      window.location.href = `${this.getAuthDomain()}/sign-out?subdomain=${currentHost}&redirect_uri=${encodeURIComponent(
        newURL
      )}`;
    } else {
      window.location.href = this.configService.replacePreviousHost(window.location.href, currentHost, newSubdomain);
    }
  }

  /**
   * Checkes, whether there is a valid access_token.
   */
  public hasValidAccessToken(): boolean {
    const expiresAt = localStorage.getItem('expires-at');
    const now = new Date();
    if (expiresAt && parseInt(expiresAt, 10) < now.getTime()) {
      return false;
    }

    return true;
  }

  /**
   * Returns the expiration date of the access_token
   * as milliseconds since 1970.
   */
  public getAccessTokenExpiration(): number {
    if (!localStorage.getItem('expires-at')) {
      return null;
    }
    return parseInt(localStorage.getItem('expires-at'), 10);
  }

  public getMarketplaceAllowedCountries(): any[] {
    const data = this.configService.getOption('allowed_countries', []);
    return data.map(country => {
      if (
        country.country_icon_url &&
        // if pass contains relative path /assets
        country.country_icon_url.startsWith('/assets')
      ) {
        country.country_icon_url = `${this.getMarketplaceUrl()}${country.country_icon_url}`;
      }
      return country;
    });
  }

  public getMarketplaceUrl(): string {
    return window.location.origin + '/';
  }

  public getAuthorizeUrl(options: { redirectUri: string; isRetroactiveUpdate?: boolean; params?: Params }): string {
    const subdomain = this.getSubdomain();

    // TODO: should be added "state" later
    const queryObject = Object.assign(
      {
        subdomain,
        response_type: 'code',
        client_id: environment.oauth.clientId
      },
      this.getUtmParamsFromRedirect(options.redirectUri)
    );

    if (options.redirectUri) {
      queryObject['redirect_uri'] = options.redirectUri;
    }

    if (options.isRetroactiveUpdate) {
      queryObject['retroactive_email_update'] = 'required';
    }

    if (options.params) {
      for (const key in options.params) {
        if (options.params.hasOwnProperty(key)) {
          queryObject[key] = options.params[key];
        }
      }
    }

    const url = `${this.getAuthDomain()}/auth/authorize?${queryParams(queryObject)}`;

    return url;
  }

  public redirectToAuthorize(redirectUri?: string, isRetroactiveUpdate = false, customParams = {}): void {
    void this.handleRedirectToAuthorize(redirectUri, isRetroactiveUpdate, customParams);
  }

  public getSignInUrl(redirectUri?: string, clearCookiesAfterRedirect?: boolean, customQueryObject = {}): string {
    const subdomain = this.getSubdomain();

    // TODO: should be added "state" later
    const queryObject = {
      response_type: 'code',
      client_id: environment.oauth.clientId,
      ...this.getUtmParamsFromRedirect(redirectUri)
    };

    if (redirectUri) {
      queryObject['redirect_uri'] = redirectUri;
    }

    if (clearCookiesAfterRedirect) {
      queryObject['clear'] = 'true';
    }

    const idClosed = this.configService.getOption('sign_in_mode') === ID_CLOSED;
    if (!idClosed) {
      if (customQueryObject['email']) {
        queryObject['email'] = encodeURIComponent(customQueryObject['email']);
      }

      if (customQueryObject['population_type']) {
        queryObject['population_type'] = customQueryObject['population_type'];
      }
    }

    return `${this.getAuthDomain()}/${subdomain}/sign-in?${queryParams(queryObject)}`;
  }

  public redirectToSignInDirectly(
    redirectUri?: string,
    clearCookiesAfterRedirect?: boolean,
    customQueryObject?: object
  ): void {
    redirectUri = getFullyQualifiedUri(redirectUri);
    window.location.href = this.getSignInUrl(redirectUri, clearCookiesAfterRedirect, customQueryObject);
  }

  public getAuthorizeSignOutUrl(): string {
    const subdomain = this.getSubdomain();

    const queryObject = {
      client_id: environment.oauth.clientId,
      subdomain
    };

    return `${this.getAuthDomain()}/sign-out?${queryParams(queryObject)}`;
  }

  public auth(login: string): any {
    return this.http.post<any>(`${environment.authUrl}/auth?subdomain=${this.getSubdomain()}`, { login }).toPromise();
  }

  public async sendActivation(email: string, firstName?: string, lastName?: string): Promise<any> {
    const data = {
      email
    };

    if (firstName) {
      data['first_name'] = firstName;
    }
    if (lastName) {
      data['last_name'] = lastName;
    }

    return this.http
      .post<any>(`${environment.authUrl}/auth/confirm/account?subdomain=${this.getSubdomain()}`, data)
      .toPromise();
  }

  public async authPass(userData: AuthPass): Promise<KnownUserInfoResponse> {
    const { login, password, rememberMe, countryCode = '', isConfirmationStep = false } = userData;
    const data = {
      login,
      password,
      remember_me: !!rememberMe,
      ...(countryCode && { country_code: countryCode }),
      isConfirmationStep
    };

    return this.http
      .post<KnownUserInfoResponse>(`${environment.authUrl}/auth/pass?subdomain=${this.getSubdomain()}`, data)
      .toPromise();
  }

  public getSignOutUrl(redirectUrl = ''): string {
    let url = `${this.getAuthDomain()}/sign-out?subdomain=${this.getSubdomain()}`;
    if (redirectUrl) {
      url += `&redirect_uri=${redirectUrl}`;
    }
    return url;
  }

  public getRedirectUri(queryParams: Params): string {
    const redirectUri = queryParams['redirect_uri'];
    const kRedirectUri = queryParams['k_redirect_uri'];

    if (redirectUri) {
      return redirectUri;
    }
    if (kRedirectUri) {
      return kRedirectUri;
    }

    const currentUrl = decodeURIComponent(window.location.href);
    const currentUrlObject = new URL(currentUrl);
    currentUrlObject.searchParams.delete('dlk');
    currentUrlObject.searchParams.delete('DLK');
    return encodeURIComponent(currentUrlObject.toString());
  }

  public async signOut(): Promise<{ sign_out_url: string }> {
    localStorage.removeItem('short_token');
    return this.http.post<{ sign_out_url: string }>(`${environment.authUrl}/auth/sign-out`, {}).toPromise();
  }

  public setupAutomaticSilentRefresh(): void {
    this.events.pipe(filter(e => e.type === 'token_expires')).subscribe(e => {
      this.silentRefresh();
    });

    this.setupRefreshTimer();
  }

  private silentRefresh(): any {
    if (typeof document === 'undefined') {
      throw new Error('silent refresh is not supported on this platform');
    }

    this.removeIFrameIfExists();

    const iframe = document.createElement('iframe');
    iframe.id = this.silentRefreshIFrameName;

    this.setupSilentRefreshEventListener();

    const url = this.getAuthorizeUrl({ redirectUri: `${window.location.origin}/silent-refresh.html` });
    iframe.setAttribute('src', url);

    if (!this.silentRefreshShowIFrame) {
      iframe.style['display'] = 'none';
    }
    document.body.appendChild(iframe);

    const errors = this.events.pipe(
      filter(e => e instanceof OAuthErrorEvent),
      first()
    );
    const success = this.events.pipe(
      filter(e => e.type === 'silently_refreshed'),
      first()
    );
    const timeout = of(new OAuthErrorEvent('silent_refresh_timeout', null)).pipe(delay(this.silentRefreshTimeout));

    return race([errors, success, timeout])
      .pipe(
        tap(e => {
          if (e.type === 'silent_refresh_timeout') {
            this.eventsSubject.next(e);
          }
        }),
        map(e => {
          if (e instanceof OAuthErrorEvent) {
            throw e;
          }
          return e;
        })
      )
      .toPromise();
    // TODO: Need to be able to pick up when change occurs to set a new timeout for refreshing token
    // Can be done with window.parent.postMessage() from the IFrame to this parent page
  }

  async isAuthenticated(): Promise<boolean> {
    if (!this.hasHarvestedTokens) {
      await this.tryLogin();
    }

    return this.isAuthenticated_BypassTokenHarvest();
  }

  isAuthenticated_BypassTokenHarvest(): boolean {
    return !!localStorage.getItem('logged-in');
  }

  setAuthenticated(expiresIn: number): void {
    localStorage.setItem('logged-in', 'true');
    localStorage.setItem('access_token_stored_at', '' + Date.now());

    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = new Date();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      localStorage.setItem('expires-at', '' + expiresAt);
    }
  }

  public setUnauthenticated(preserveLocalStorageValues?: string[]): void {
    this.removeIFrameIfExists();
    this.clearAccessTokenTimer();
    this.knownUserService.clearAll();
    clearLocalStorage(false, preserveLocalStorageValues);
  }

  public async getAccountInfo(): Promise<ProfileResponse> {
    const query = this.knownUserService.knownUserQueryParams ? `?${this.knownUserService.knownUserQueryParams}` : '';
    return this.http.get<ProfileResponse>(`${environment.apiUrl}/api/profile${query}`).toPromise();
  }

  public setAuthorizeData(responseData: {
    access_token: string;
    short_token: string;
    scope: string;
    expires_in: number;
  }): void {
    localStorage.setItem('access_token', responseData.access_token);
    localStorage.setItem('short_token', responseData.short_token);

    if (responseData.scope) {
      this.permissionService.define(responseData.scope.split(' '));
      this.clearKnownUserRelatedData(responseData.scope.split(' '));
    }

    this.setAuthenticated(responseData.expires_in);
    this.hasHarvestedTokens = true;
    this.clearHashFragment({});
  }

  protected callOnTokenReceivedIfExists(options: LoginOptions): void {
    if (options.onTokenReceived) {
      const tokenParams = {
        // idClaims: that.getIdentityClaims(),
        // idToken: that.getIdToken(),
        // accessToken: that.getAccessToken(),
        // state: that.state
      };
      options.onTokenReceived(tokenParams);
    }
  }

  protected setupAccessTokenTimer(): void {
    const expiration = this.getAccessTokenExpiration();
    const storedAt = this.getAccessTokenStoredAt();
    const timeout = this.calcTimeout(storedAt, expiration);

    this.ngZone.runOutsideAngular(() => {
      this.accessTokenTimeoutSubscription = of(new OAuthInfoEvent('token_expires', 'access_token'))
        .pipe(delay(timeout))
        .subscribe(e => {
          this.ngZone.run(() => {
            this.eventsSubject.next(e);
          });
        });
    });
  }

  protected clearAccessTokenTimer(): void {
    if (this.accessTokenTimeoutSubscription) {
      this.accessTokenTimeoutSubscription.unsubscribe();
    }
  }

  protected setupRefreshTimer(): void {
    if (typeof window === 'undefined') {
      throw new Error('timer not supported on this platform');
    }

    this.clearAccessTokenTimer();
    interval(1000)
      .pipe(
        switchMap(() => of(this.isAuthenticated_BypassTokenHarvest())),
        filter(i => i),
        take(1)
      )
      .subscribe(() => {
        this.setupAccessTokenTimer();
        this.events.pipe(filter(e => e.type === 'token_received')).subscribe(_ => {
          this.clearAccessTokenTimer();
          this.setupAccessTokenTimer();
        });
      });
  }

  protected calcTimeout(storedAt: number, expiration: number): number {
    const delta = (expiration - storedAt) * this.timeoutFactor;
    return delta;
  }

  protected removeSilentRefreshEventListener(): void {
    if (this.silentRefreshPostMessageEventListener) {
      window.removeEventListener('message', this.silentRefreshPostMessageEventListener);
      this.silentRefreshPostMessageEventListener = null;
    }
  }

  protected setupSilentRefreshEventListener(): void {
    this.removeSilentRefreshEventListener();

    this.silentRefreshPostMessageEventListener = (e: MessageEvent): void => {
      let expectedPrefix = '#';

      if (this.silentRefreshMessagePrefix) {
        expectedPrefix += this.silentRefreshMessagePrefix;
      }

      if (!e || !e.data || typeof e.data !== 'string') {
        return;
      }

      const prefixedMessage: string = e.data;

      if (!prefixedMessage.startsWith(expectedPrefix)) {
        return;
      }

      const message = '#' + prefixedMessage.substr(expectedPrefix.length);

      void this.tryLogin({
        customHashFragment: message,
        preventClearHashAfterLogin: true,
        onLoginError: err => {
          this.eventsSubject.next(new OAuthErrorEvent('silent_refresh_error', err));
        },
        onTokenReceived: () => {
          this.eventsSubject.next(new OAuthSuccessEvent('silently_refreshed'));
        }
      }); // .catch(err => console.error('tryLogin during silent refresh failed', err));
    };

    window.addEventListener('message', this.silentRefreshPostMessageEventListener);
  }

  protected getAccessTokenStoredAt(): number {
    return parseInt(localStorage.getItem('access_token_stored_at'), 10);
  }

  private removeIFrameIfExists(): void {
    const existingIFrame = document.getElementById(this.silentRefreshIFrameName);
    if (existingIFrame) {
      document.body.removeChild(existingIFrame);
    }
  }

  private clearHashFragment(options: LoginOptions): void {
    this.eventsSubject.next(new OAuthSuccessEvent('token_received'));

    // Clear the hash fragment
    if (!options.preventClearHashAfterLogin) {
      const pathWithoutHash = this.location.path(false);
      this.location.replaceState(pathWithoutHash);
    }
    this.harvestingTokenFromHashFragment = false;

    this.callOnTokenReceivedIfExists(options);
  }

  private handleRedirectToAuthorize(redirectUri = '', isRetroactiveUpdate = false, customParams: Params): void {
    redirectUri = getFullyQualifiedUri(redirectUri);

    if (this.corporateRedirectUri && !redirectUri.includes(CorporateRedirectToken.PREFIX_TOKEN)) {
      redirectUri += `${CorporateRedirectToken.PREFIX_TOKEN}${encodeURIComponent(this.corporateRedirectUri)}${
        CorporateRedirectToken.SUFFIX_TOKEN
      }`;
    }

    void this.configService.getAuthInfo().then(data => {
      const currentHost = window.location.host.split('.')[0];
      const prevSubdomain = this.getPrevSubdomain();
      const subdomain = this.getSubdomain();

      if (subdomain === 'controlpanel') {
        redirectUri = data.redirect_to ? data.redirect_to : redirectUri;
      } else if (currentHost === prevSubdomain) {
        redirectUri = this.configService.replacePreviousHost(redirectUri, prevSubdomain, subdomain);
      }

      const authorizeUrl = this.getAuthorizeUrl({ redirectUri, isRetroactiveUpdate, params: customParams });
      window.location.href = authorizeUrl;
    });
  }

  private clearKnownUserRelatedData(scopes: string[]): void {
    if (scopes.includes('search:recent') && !scopes.includes('known:access')) {
      this.knownUserService.clearAll();
      this.knownUserService.clearSkipPassword();
    }
  }

  private getEnvironmentOAuthUrl(): string {
    return this.isWorkingAdvantage ? environment.oauth.url.replace(BP_HOST_NAME, WA_HOST_NAME) : environment.oauth.url;
  }

  private getUtmParamsFromRedirect(redirectUri: string): { [key: string]: any } {
    if (!redirectUri) {
      return {};
    }
    try {
      const redirect = new URL(redirectUri);
      const redirectQueryObject = {};
      for (const param of this.UTM_PARAMS) {
        if (redirect.searchParams.has(param)) {
          redirectQueryObject[param] = redirect.searchParams.get(param);
        }
      }
      return redirectQueryObject;
    } catch (e) {
      return {};
    }
  }
}
