/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { cdnHostTypes, ConfigService } from '@shared/services/config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MultiplierOption } from '@app/offers/offers-list/offers-list-admin/multiplier-option.model';
import { Observable } from 'rxjs';
import { OfferInlineEditForm } from '@app/offers/offers-list/offers-list-admin/offer-inline-edit-form.component';

interface DictionaryResponse {
  id: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class OffersListAdminService {

  private cdnUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.cdnUrl = configService.getCdn(cdnHostTypes.apiHost) || environment.apiUrl;
  }

  getCategoryTagsResolutions(ids: string[]): Observable<DictionaryResponse[]> {
    const tagIds = ids && ids.length > 0 ? ids.join(',') : 'null';
    return this.http.get<{ code: string; title: string }[]>(`${this.cdnUrl}/api/dictionaries/category-tags/${tagIds}`)
      .pipe(
        map(categories => (categories || []).map(i => ({ id: i.code, title: i.title })))
      );
  }

  getBrandTagsResolutions(ids: string[]): Observable<DictionaryResponse[]> {
    const tagIds = ids && ids.length > 0 ? ids.join(',') : 'null';
    return this.http.get<{ tag_id: string; title: string }[]>(`${this.cdnUrl}/api/dictionaries/brand-tags/${tagIds}`
    ).pipe(
      map(brands => (brands || []).map(i => ({ id: i.tag_id, title: i.title })))
    );
  }

  setSearchWeight(multiplierOption: MultiplierOption, offerId: string): any {
    return this.http.patch(`${environment.apiUrl}/api/platform/offers/${offerId}/search-weights`, multiplierOption);
  }

  updateOffer(offerId: string, data: OfferInlineEditForm): any {
    return this.http.patch(`${environment.apiUrl}/api/platform/offers/${offerId}/search-text`, {
      header: data.headline,
      locations: (data.locations || '').replace(/,/g, '|'),
      description: data.description,
      keywords: (data.keywords || '').replace(/,/g, '|'),
      stop_keywords: (data.stopKeywords || '').replace(/,/g, '|')
    });
  }
}
