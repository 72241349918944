<section class="container main-container wide-container cta-element"
				 *ngIf="isCtaElement">
	<app-zone-header *ngIf="!!zoneElement.name"
									 [data]="zoneElement"
									 [groupTabIndex]="groupTabIndex"
									 (showAllClick)="showAllClickHandler($event)"></app-zone-header>

	<ng-container *ngIf="zoneElement.type === 'cta_block'">
		<ng-container *ngIf="ctaElements.length > 1; else singleCtaBlock">
			<div class="cta-carousel"
					 (touchstart)="position = $event.changedTouches[0].pageX"
					 (touchend)="move($event.changedTouches[0].pageX)">
				<ngb-carousel #carousel
											[appAutoTabIndex]="groupTabIndex"
											[interval]="false"
											[showNavigationArrows]="false"
											(slide)="onSlide($event)">

					<ng-container *ngFor="let ctaElement of ctaElements; trackBy: trackByGuid">
						<ng-template ngbSlide>
							<ng-container *ngTemplateOutlet="ctaBlock; context: {$implicit: ctaElement}"></ng-container>
						</ng-template>
					</ng-container>

				</ngb-carousel>

				<button type="button"
								[appAutoTabIndex]="groupTabIndex + 1"
								class="cta-carousel-button__prev disabled"
								(click)="carousel.prev()">
					<div class="icon-wrapper theme-background-color-secondary">
						<img ngSrc="/shared-assets/icons/ic-arrow-left-14-px.svg"
                 height="14"
                 width="9"
                 alt="arrow left">
					</div>
				</button>
				<button	type="button"
								[appAutoTabIndex]="groupTabIndex + 2"
								class="cta-carousel-button__next"
								(click)="carousel.next()">
					<div class="icon-wrapper theme-background-color-secondary">
						<img ngSrc="/shared-assets/icons/ic-arrow-right-14-px.svg"
                 height="14"
                 width="9"
                 alt="arrow right">
					</div>
				</button>
			</div>
		</ng-container>

		<ng-template #singleCtaBlock>
			<ng-container *ngTemplateOutlet="ctaBlock; context: {$implicit: ctaElements[0]}"></ng-container>
		</ng-template>
	</ng-container>

	<ng-template #ctaBlock
							 let-ctaElement>
		<app-cta-block [ctaBlock]="ctaElement"
									 [groupTabIndex]="groupTabIndex"
									 [wideContainerClass]="wideContainerClass"
									 [anchorLink]="anchorLink"
                   [isEagerLoading]="isEagerLoading"
									 (handleCta)="handleCtaClick(ctaElement)">
		</app-cta-block>
	</ng-template>

	<app-cta-product *ngIf="zoneElement.type === 'cta_product'"
									 [ctaProduct]="ctaElements[0]"
									 [wideContainerClass]="wideContainerClass"
									 [anchorLink]="anchorLink"
                   [isEagerLoading]="isEagerLoading"
									 (handleCta)="handleCtaClick(ctaElements[0])">
	</app-cta-product>
</section>
