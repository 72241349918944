/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Ad } from '@shared/models/ad.model';
import { DestinationUrlRequestParamsInterface } from '@shared/services/destination-url-request-params.interface';
import { OfferModel } from '@shared/models/offer.model';
import {
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutService } from '@shared/services/click-out.service';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { OffersAnalyticsService } from '@shared/services/analytics/offers-analytics.service';
import { OffersService } from '@shared/services/offers.service';
import { SearchService } from '@shared/services/search/search.service';
import { DirectClickOutOffersService } from '@shared/services/direct-click-out/direct-click-out-offers.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { ZonesSectionElementItemsAnalyticsService } from '@zones/services/analytics/zones-section-element-items-analytics.service';
import { PhoneCtaService } from '@shared/services/phoneCta.service';
import { UrlService } from '@shared/services/url.service';
import { TokensService } from '@shared/services/tokens.service';
import { AdsOffersService } from '../ads/ads-offers.service';
import { SOURCE_ID } from '@app/shared/constants/source-id.constants';
import { SingleUseCodeService } from '@app/shared/components/coupon-modal/single-use-code.service';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeClickEvent, AdobeEventTypeEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';

const getAnalyticsEventData = (data: any) => {
  const dataType = typeof data;
  if (dataType === 'string') {
    return { label: data };
  } else {
    if (dataType === 'object') {
      if (Array.isArray(data)) {
        return { ads: data };
      }
      return data;
    }
  }
  return {};
};

@Injectable({
  providedIn: 'root'
})
export class DirectClickOutAdCardsService extends DirectClickOutOffersService {

  constructor(
    protected adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
    protected clickOutService: ClickOutService,
    protected breadcrumbsService: BreadcrumbsService,
    protected analyticsService: AnalyticsService,
    protected offersAnalytics: OffersAnalyticsService,
    protected offersService: OffersService,
    protected searchService: SearchService,
    protected zonesSectionElementItemsAnalyticsService: ZonesSectionElementItemsAnalyticsService,
    protected phoneCtaService: PhoneCtaService,
    protected urlService: UrlService,
    protected tokensService: TokensService,
    protected adsOffersSevice: AdsOffersService,
    protected singleUseCodeService: SingleUseCodeService
  ) {
    super(
      adobeAnalyticsEventsService,
      router,
      modalService,
      clickOutService,
      breadcrumbsService,
      analyticsService,
      offersAnalytics,
      offersService,
      searchService,
      phoneCtaService,
      singleUseCodeService
    );
  }

  getGeneralAnalyticsEvents(action: any, data: any, customGAEventAction?: string, customGAEventCategory?: string, isOpenEnrollment = false) {
    return isOpenEnrollment
      ? [
        new AnalyticsGAEventModel(customGAEventAction || action, {
          category: customGAEventCategory || action,
          label: data
        })
      ]
      : [
        new AnalyticsInternalEventModel(action, getAnalyticsEventData(data)),
        new AnalyticsGAEventModel(customGAEventAction || action, {
          category: customGAEventCategory || action,
          label: data
        })
      ];
  }

  getOpenEnrollmentAnalyticsEvents(ad: Ad, internal = false, isOpenEnrollment = false) {
    return this.getGeneralAnalyticsEvents(
      internal ? 'internal-click' : 'click-out',
      this.offersAnalytics.getAnalyticsGALabel(ad) + ' | open-enrollment',
      null,
      'open-enrollment',
      isOpenEnrollment
    );
  }

  disableHover(ad: Ad) {
    return !(ad.hasDestinationUrl || ad.descriptionDetails || ad.code);
  }

  getOfferFromAd(ad: Ad): OfferModel {
    const isEbg = ad.isEbg || this.tokensService.isTokenPresent(ad.destinationUrl, 'ebghost');
    return new OfferModel({
      guid: ad.contentGuid || ad.guid,
      header_long: ad.title,
      header_short: ad.titleShort,
      code: ad.code,
      destination_url: ad.destinationUrl,
      destination_url_new_tab: ad.destination_url_new_tab,
      destination_url_type: ad.urlType,
      phone: ad.phone,
      phone_note: ad.phone_note,
      phone_action: ad.phone_action,
      instruction_text: ad.instruction_text,
      code_instructions: ad.code_instructions,
      vendor_brand: {
        title: ad.company || '',
        logo_square: ad.vendorImage || ''
      },
      vendor: {
        title: ad.company || ''
      },
      isEbg,
      brand_override: ad.brandOverride || '',
      source_based_params: ad.source_based_params || [],
      paylogix_enrolled_header_short: ad.paylogix_enrolled_header_short || ''
    });
  }

  async handleAdClick(
    ad: Ad,
    source: 'card' | 'cta' | 'cta-zipcode' | 'paylogix-enrollment',
    zoneSectionElement: ZoneSectionElement,
    eventType = '',
    eventAction = '',
    withParams: DestinationUrlRequestParamsInterface = {},
    isOpenEnrollment = false,
    adobeInfo?: AdobeClickEvent,
  ) {

    ad.views += 1;
    const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
    const offer = this.getOfferFromAd(ad);
    const openEnrollmentAnalyticsInternal = this.getOpenEnrollmentAnalyticsEvents(ad, true, isOpenEnrollment);
    const openEnrollmentAnalyticsClickOut = this.getOpenEnrollmentAnalyticsEvents(ad, false, isOpenEnrollment);
    const additionalEvents = this.getAdAdditionalEvents(ad, zoneSectionElement);

    if (!ad.isAllowedDestinationUrl && !(source === 'card' && ad.descriptionDetails)) {
      return;
    }

    const redirectToOfferDetails = () => {
      if (zoneSectionElement) {
        this.analyticsService.eventsTrack(this.zonesSectionElementItemsAnalyticsService.getAnalyticsEvents('click', zoneSectionElement, ad));
      }
      if (isOpenEnrollment) {
        this.analyticsService.eventsTrack(openEnrollmentAnalyticsInternal);
      }
      if (eventType) {
        this.analyticsService.eventsTrack(this.getGeneralAnalyticsEvents(eventType, this.offersAnalytics.getAnalyticsGALabel(ad), eventAction));
      }

      if (offer.guid) {
        const queryParams: Params = {};
        let internalQueryParams: Params = {};

        if (ad.destinationUrl?.startsWith('/')) {
          if (ad.append_g3_url_params) {
            this.adsOffersSevice.appendG3Url = ad.append_g3_url_params;
          }
        } else if (ad.isEbg || this.clickOutService.isInternalClickOut(ad.destinationUrl)) {
          if (ad.append_internal_url_params) {
            this.adsOffersSevice.appendInternalUrl = ad.append_internal_url_params;
          }
        } else if (ad.append_url_params) {
          this.adsOffersSevice.appendUrl = ad.append_url_params;
        }

        if (ad.append_g3_url_params) {
          internalQueryParams = ad.append_g3_url_params
            .split('&')
            .reduce((acc, el) => {
              const param = el.split('=');
              acc[param[0]] = param[1];
              return acc;
            }, {});
        }

        void this.router.navigate([`/offers/${offer.guid}`], { queryParams: { ...queryParams, ...internalQueryParams } });
      }
    };

    if (source === 'card' && ad.descriptionDetails) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      // internal click
      redirectToOfferDetails();

    } else if (this.isDisplayModalForAd(ad, source)) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.REVEAL_CODE;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      this.analyticsService.eventsTrack(this.getAnalyticsEvents('click-reveal-code', zoneSectionElement, ad));
      this.increaseViewCounter(offer);
      const modal = this.getCouponModal();
      modal.componentInstance.brandLogo = offer.vendorBrand.logoSquare;
      modal.componentInstance.offer = offer;
      modal.componentInstance.code = offer.code;
      modal.componentInstance.modalEvents.subscribe(event => {
        if (zoneSectionElement) {
          void this.handleModalEventsWithZoneSection(event, offer, ad, zoneSectionElement, adobeInfo);
        } else {
          if (isOpenEnrollment) {
            if (event.type === 'click-copy-code') {
              this.analyticsService.eventsTrack(openEnrollmentAnalyticsInternal);
            } else if (event.type === 'cta-button-click') {
              this.analyticsService.eventsTrack(openEnrollmentAnalyticsClickOut);
            }
          }
          void this.handleModalEvents(event, offer, adobeInfo);
        }
      });

      modal.componentInstance.phoneAction.subscribe(url => {
        if (isOpenEnrollment) {
          if (this.urlService.isExternalUrl(url)) {
            this.analyticsService.eventsTrack(openEnrollmentAnalyticsInternal);
          } else {
            this.analyticsService.eventsTrack(openEnrollmentAnalyticsClickOut);
          }
        }
        if (adobeInfo) {
          adobeInfo.event_type = this.urlService.isExternalUrl(url) ? AdobeEventTypeEnum.EXTERNAL_CLICKOUT : AdobeEventTypeEnum.INTERNAL_CLICKOUT;
          adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
          void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
        }
        this.phoneCtaService.phoneCtaClick(url, ad, true);
      });

      modal.componentInstance.phoneClick.subscribe(phone => {
        if (isOpenEnrollment) {
          this.analyticsService.eventsTrack(openEnrollmentAnalyticsInternal);
        }
        this.phoneCtaService.phoneClick(phone, ad);
      });

    } else if (ad.destinationUrl && ad.destinationUrl.startsWith('/')) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }

      if (isOpenEnrollment) {
        this.analyticsService.eventsTrack(openEnrollmentAnalyticsInternal);
      }

      this.analyticsService.eventsTrack(this.getAnalyticsEvents('click', zoneSectionElement, ad));

      if (ad.urlType === 'dynamic') {
        const options = {
          guid: ad.contentGuid,
          withParams,
          data: {
            logo: '',
            brand: '',
            guid: ad.contentGuid,
            urlParams: this.getClickOutParams(ad)
          }
        };

        await this.clickOutService.clickOut(
          '',
          'offer_dynamic',
          options,
          ad.destination_url_new_tab,
          sourceId,
          ad.source_based_params
        );

      } else {
        const urlParams = ad.append_g3_url_params || '';
        let destinationUrl = ad.destinationUrl;
        if (!!urlParams) {
          destinationUrl += `${ad.destinationUrl.includes('?') ? '&' : '?'}${urlParams}`;
        }
        void this.router.navigateByUrl(destinationUrl);
      }

    } else if (ad.urlType === 'dynamic') {
      if (adobeInfo) {
        adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValue(ad);
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }

      const analyticEvents = [];

      if (isOpenEnrollment) {
        analyticEvents.push(...openEnrollmentAnalyticsClickOut);
      }

      if (eventType && Object.keys(withParams).length) {
        let analyticData = this.offersAnalytics.getAnalyticsGALabel(ad);
        Object.keys(withParams).forEach(key => {
          analyticData += ` | ${key}: ${withParams[key]}`;
        });
        analyticEvents.push(...this.getGeneralAnalyticsEvents(eventType, analyticData));
      }
      analyticEvents.push(
        ...this.getAnalyticsEvents('click-out', zoneSectionElement, ad),
        ...additionalEvents
      );

      await this.clickOutService.clickOut(
        '',
        'offer_dynamic',
        {
          analyticEvents,
          guid: ad.contentGuid,
          withParams,
          data: {
            logo: ad.vendorImage,
            brand: ad.company,
            guid: ad.contentGuid,
            urlParams: this.getClickOutParams(ad)
          }
        },
        ad.destination_url_new_tab,
        sourceId,
        ad.source_based_params
      );

      this.increaseViewCounter(offer);
    } else if (ad.destinationUrl) {
      if (adobeInfo) {
        adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValue(ad);
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      this.increaseViewCounter(offer);
      const analyticsEvents = [
        ...this.getAnalyticsEvents('click-out', zoneSectionElement, ad),
        ...additionalEvents
      ];

      if (isOpenEnrollment) {
        analyticsEvents.push(...openEnrollmentAnalyticsClickOut);
      }

      await this.clickOutService.clickOut(
        ad.destinationUrl,
        null,
        {
          analyticEvents: analyticsEvents,
          data: {
            logo: ad.vendorImage,
            brand: ad.company,
            guid: ad.contentGuid,
            urlParams: this.getClickOutParams(ad)
          }
        },
        ad.destination_url_new_tab,
        sourceId,
        ad.source_based_params
      );

    } else if (ad.descriptionDetails) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      redirectToOfferDetails();
    }
  }

  isDisplayModalForAd(ad: Ad, source: string): boolean {
    const hasCouponForModal = ad.hasCouponCode() && !this.isAdExternalUrlWithSucToken(ad);
    return (hasCouponForModal || ad.hasPhone()) && source !== 'cta-zipcode' && !ad.isPaylogixAd;
  }

  getAnalyticsEvents(action, zoneSectionElement: ZoneSectionElement, ad: Ad): AnalyticsEvent[] {
    if (zoneSectionElement) {
      const result = [];
      result.push(...this.zonesSectionElementItemsAnalyticsService.getAnalyticsEvents(action, zoneSectionElement, ad));
      // if this ad is linked to the offer
      if (ad.contentGuid) {
        result.push(...this.offersAnalytics.getAnalyticsEvents(action, ad));
      }
      return result;
    } else {
      return this.offersAnalytics.getAnalyticsEvents(action, ad);
    }
  }

  async nonClickOutHandler(ad: Ad, eventType = '', eventAction = ''): Promise<void> {
    const analyticEvents = this.getGeneralAnalyticsEvents(eventType, eventType, eventAction);
    if (this.urlService.isExternalUrl(ad.adUrl)) {
      await this.clickOutService.clickOut(
        ad.adUrl,
        null,
        {
          analyticEvents, data: {
            logo: ad.vendorImage,
            brand: ad.company,
            guid: ad.contentGuid,
            urlParams: this.getClickOutParams(ad)
          }
        },
        ad.destination_url_new_tab
      );
    } else if (ad.adUrl[0] === '/') {
      this.analyticsService.eventsTrack(analyticEvents);
      this.breadcrumbsService.setUrl(ad.adUrl);
      void this.router.navigateByUrl(ad.adUrl);
    } else {
      const internalParams = ad.append_internal_url_params && `?${ad.append_internal_url_params}` || '';
      this.analyticsService.eventsTrack(analyticEvents);
      void this.router.navigate([`/offers/${ad.id}${internalParams}`]);
    }
  }

  protected getAdAdditionalEvents(ad: Ad, zoneSectionElement: ZoneSectionElement): AnalyticsEvent[] {
    const events: AnalyticsEvent[] = [];

    if (this.isAdExternalUrlWithSucToken(ad)) {
      events.push(...this.getAnalyticsEvents('click-reveal-code', zoneSectionElement, ad));
    }

    return events;
  }

  protected isAdExternalUrlWithSucToken(ad: Ad): boolean {
    return ad.hasCouponCode() && this.singleUseCodeService.isExternalUrlWithSucToken(ad.destinationUrl, ad.code);
  }

  protected async handleModalEventsWithZoneSection(event, offer: OfferModel, ad: Ad, zoneSectionElement: ZoneSectionElement, adobeInfo?: AdobeClickEvent) {
    if (event && event.type) {
      switch (event.type) {
        case 'click-copy-code':
          if (adobeInfo) {
            adobeInfo.event_type = AdobeEventTypeEnum.COPY_CODE;
            adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
            void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
          }
          this.analyticsService.eventsTrack(this.getAnalyticsEvents('click-copy-code', zoneSectionElement, ad));
          break;
        case 'cta-button-click':
          const analyticEvents = this.getAnalyticsEvents('click-out', zoneSectionElement, ad);
          const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
          const data = {
            logo: ad.vendorImage,
            brand: ad.company,
            guid: ad.contentGuid,
            urlParams: this.getClickOutParams(ad)
          };

          if (adobeInfo) {
            adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValue(ad);
            adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
            void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
          }

          if (ad.urlType === 'dynamic') {

            await this.clickOutService.clickOut(
              '',
              'offer_dynamic',
              {
                analyticEvents,
                guid: ad.contentGuid,
                data
              },
              ad.destination_url_new_tab,
              sourceId,
              ad.source_based_params
            );

          } else {
            await this.clickOutService.clickOut(
              ad.destinationUrl,
              null,
              {
                analyticEvents,
                data
              },
              ad.destination_url_new_tab,
              sourceId,
              ad.source_based_params
            );
          }
          break;
      }
    }
  }

  protected getClickOutParams(ad: Ad): string {
    if (ad.destinationUrl?.startsWith('/')) {
      return ad.append_g3_url_params;
    }
    return ad.isEbg || this.clickOutService.isInternalClickOut(ad.destinationUrl)
      ? ad?.append_internal_url_params
      : ad?.append_url_params;
  }

}
