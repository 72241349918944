/* eslint-disable typescriptESlintPlugin/no-misused-promises */
import { ActivatedRoute, Params } from '@angular/router';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subject
} from 'rxjs';
import { Injectable } from '@angular/core';
import { InlineAdminQueryType } from '@shared/components/offers-list/offers-list-admin/inline-admin-query.type';
import { MultiplierOption } from '@shared/components/offers-list/offers-list-admin/multiplier-option.model';
import { OfferModel } from '@shared/models/offer.model';
import { OffersListAdminService } from '@shared/components/offers-list/offers-list-admin/offers-list-admin.service';
import { SearchResultsTodayDealModel } from '@shared/models/search-results-today-deal.model';

export type QueryParamsNameResolutions = Map<InlineAdminQueryType, Map<string, string>>;

@Injectable({
  providedIn: 'root'
})
export class OffersListAdminLayoutService {

  queryParamsNameResolutions: Observable<QueryParamsNameResolutions>;
  inlineAdminLayoutChange: Observable<boolean>;
  takeoverState: Observable<boolean>;

  private inlineAdminLayoutSubject = new Subject<boolean>();
  private takeoverSubject = new BehaviorSubject<boolean>(false);
  private queryParamsNameResolutionsSubject = new BehaviorSubject<QueryParamsNameResolutions>(new Map());

  constructor(
    private route: ActivatedRoute,
    private offersListAdminService: OffersListAdminService
  ) {
    this.inlineAdminLayoutChange = this.inlineAdminLayoutSubject.asObservable();
    this.takeoverState = this.takeoverSubject.asObservable();
    this.takeoverSubject.next(OffersListAdminLayoutService.isHomepageTakeoverEditMode());
    this.queryParamsNameResolutions = this.queryParamsNameResolutionsSubject.asObservable();
    this.route.queryParams.subscribe(async data => this.initQueryParams(data));
  }

  /**
   * Defines if homepage takeover admin edit mode is enabled
   *
   * @returns true if homepage takeover admin edit mode is enabled
   */
  static isHomepageTakeoverEditMode(): boolean {
    return sessionStorage.getItem('admin_edit_mode') === 'homepage_takeover';
  }

  private static getMultiplierValue(value: string, offerData: OfferModel | SearchResultsTodayDealModel, type: InlineAdminQueryType): number {
    let multiplierString;

    switch (type) {
      case InlineAdminQueryType.Brand:
        multiplierString = offerData['brand_tag_ids_string'];
        break;
      case InlineAdminQueryType.Category:
        multiplierString = offerData['cat_code_tag_ids_string'];
        break;
      case InlineAdminQueryType.Query:
        multiplierString = offerData['keywords_string'];
        break;
      default:
        throw new Error('Not supported parameter: "type"');
    }

    const keywordsSplit = (multiplierString || '').split(',');
    return keywordsSplit.filter((keyword: string) => keyword === value).length;
  }

  private static getQueryParams(): InlineAdminQueryType[] {
    return Object.values(InlineAdminQueryType);
  }

  private static getMultiplierQueryParams(): InlineAdminQueryType[] {
    return Object.values(InlineAdminQueryType).filter(value => value !== InlineAdminQueryType.Class);
  }

  private static needsDecoding(value: string): boolean {
    return /%[0-9A-Fa-f]{2}/.test(value);
  }

  private static queryParamsParser(queryParams: Params): QueryParamsNameResolutions {
    const queryParamsNameResolutions: QueryParamsNameResolutions = new Map();
    OffersListAdminLayoutService.getQueryParams()
      .forEach(param => {
        if (!queryParams[param]) {
          return;
        }

        const value = OffersListAdminLayoutService.needsDecoding(queryParams[param]) ? decodeURIComponent(queryParams[param]) : queryParams[param];

        const paramValues = queryParamsNameResolutions.set(param, new Map()).get(param);

        value.split(',').forEach(i => {
          paramValues.set(i, null);
        });
      });

    return queryParamsNameResolutions;
  }

  async initQueryParams(queryParams: Params): Promise<void> {
    const qValuesMap = OffersListAdminLayoutService.queryParamsParser(queryParams);
    const [brandsResponse, categoriesResponse] = await forkJoin([
      qValuesMap.get(InlineAdminQueryType.Brand)
        ? this.offersListAdminService.getBrandTagsResolutions(
          Array.from(qValuesMap.get(InlineAdminQueryType.Brand).keys()).map(i => this.getQueryParamValue(i)))
        : of([]),
      qValuesMap.get(InlineAdminQueryType.Category)
        ? this.offersListAdminService.getCategoryTagsResolutions(
          Array.from(qValuesMap.get(InlineAdminQueryType.Category).keys()).map(i => this.getQueryParamValue(i)))
        : of([])
    ]).toPromise();
    this.queryParamsChangeHandler({
      qValuesMap,
      brandsResponse,
      categoriesResponse
    });
  }

  getQueryParamValue(value: string): string {
    // get only part that is behind ^
    return value ? value.split('^')[0] : null;
  }

  getQueryOptionsForOffer(queryParams: QueryParamsNameResolutions, offerData: OfferModel | SearchResultsTodayDealModel): MultiplierOption[] {
    return OffersListAdminLayoutService.getMultiplierQueryParams()
      .map(type => ({ type, value: decodeURIComponent(this.route.snapshot.queryParamMap.get(type) || '') }))
      .reduce((res, i) => {
        (i.value || '').split(',').forEach((queryParamItem: string) => {
          if (queryParamItem) {
            const queryParamValue = this.getQueryParamValue(queryParamItem);
            const queryParamName = this.getQueryParamTitle(queryParamValue, i.type, queryParams) || queryParamValue;
            if (queryParamValue) {
              res.push({
                type: i.type,
                id: queryParamValue,
                name: queryParamName,
                multiplier: OffersListAdminLayoutService.getMultiplierValue(queryParamValue, offerData, i.type)
              });
            }
          }
        });
        return res;
      }, [] as MultiplierOption[]);
  }

  triggerInlineAdminLayout(enabled: boolean): void {
    this.inlineAdminLayoutSubject.next(enabled);
  }

  handleTakeoverState(enabled: boolean): void {
    this.takeoverSubject.next(enabled);
  }

  private queryParamsChangeHandler({ qValuesMap, brandsResponse, categoriesResponse }): void {
    const brandValues = qValuesMap.get(InlineAdminQueryType.Brand);
    brandsResponse.forEach(i => brandValues.set(i.id, i.title));

    const categoriesValues = qValuesMap.get(InlineAdminQueryType.Category);
    categoriesResponse.forEach(i => categoriesValues.set(i.id, i.title));

    this.queryParamsNameResolutionsSubject.next(qValuesMap);
  }

  private getQueryParamTitle(id: string, queryParam: InlineAdminQueryType, queryParams: QueryParamsNameResolutions): string {
    const qResolution = queryParams && queryParams.get(queryParam);
    return qResolution ? qResolution.get(id) : null;
  }
}
