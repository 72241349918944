<app-offer-badge *ngIf="model"
                 [offerData]="model"
                 [offerClass]="model.class"
                 [withRightCorners]="true"
                 class="position-right">
</app-offer-badge>

<div class="widget hotel-widget widget-b">

  <div class="widget-image-container">

    <img
      [ngSrc]="backgroundImage | imageUrl : getBackgroundOptions() : false"
      [priority]="isEagerLoading"
      (error)="imageHandlerService.swapImage($event.target)"
      [placeholder]="imageHandlerService.getDefaultPlaceholder"
      alt=""
      class="background-widget-image"
      fill>

    <div class="logo-wrapper-b">
      <img [ngSrc]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           [priority]="isEagerLoading"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           alt="taw"
           width="70"
           height="70"
           class="logo" />
    </div>
  </div>

  <div class="relative widget-form-container">
    <div class="logo-wrapper-b-mobile">
      <img [ngSrc]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           width="70"
           height="70"
           [priority]="isEagerLoading"
           alt="taw"
           class="logo" />
    </div>

    <div class="flex-container flex-row correct-margin">
      <div class="image">
        <img [ngSrc]="'/assets/icons/ic-hotel.svg'"
             (error)="imageHandlerService.swapImage($event.target)"
             [placeholder]="imageHandlerService.getDefaultPlaceholder"
             height="30"
             width="30"
             class="ic-hotel"
             alt="">
      </div>
      <div class="header-text">
        <h3>{{ header }}</h3>
      </div>
    </div>

    <div class="content widget-form-wrapper">
      <app-hotels-form (clickOut)="hotelClickOut($event)"
                       [model]="model"
                       widgetClass="large-widget">
      </app-hotels-form>
    </div>

    <div class="logo-wrapper">
      <img [ngSrc]="'/assets/tickets-at-work.png'"
           (error)="imageHandlerService.swapImage($event.target)"
           [placeholder]="imageHandlerService.getDefaultPlaceholder"
           width="70"
           height="70"
           alt="taw"
           class="logo" />
    </div>
  </div>
</div>
